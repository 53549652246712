import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import { Auth0Provider } from "@auth0/auth0-react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    cacheLocation={"localstorage"}
    useRefreshTokens={true}
    authorizationParams={{
      redirect_uri: window.location.origin + "/admin_dashboard",
      scope:
        "openid profile email offline_access read:current_user update:current_user_metadata",
      audience: `EndoAPIURL`,
    }}
  >
    <App />
  </Auth0Provider>
  // </React.StrictMode>
);
