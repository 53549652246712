import ReactDOM from "react-dom";
import cancelImage from "../../images/cancel.svg";
import { TextField } from "../Shared/TextField";
import { DateField } from "../Shared/DateField";
import { useState } from "react";
import { ActionButton } from "../Shared/ActionButton";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function CreatePatientRecord({
  show,
  onCloseButtonClick,
  sendOutput,
  callback,
}) {
  const [patientName, setPatientName] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [referredBy, setReferredBy] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  async function callPatientRecordCreate() {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          // audience: `EndoAPIURL`,
          // scope: "openid profile email offline_access read:current_user",
        },
      });

      const jsonData = {
        patient_record: {
          patient_name: patientName,
          patient_email: patientEmail,
          appointment_date: appointmentDate,
          referred_by: referredBy,
        },
      };

      const res = await fetch(`${rootURL}/patient_new`, {
        // Enter your IP address here
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        mode: "cors",
        body: JSON.stringify(jsonData), // body data type must match "Content-Type" header
      });
      const data = await res.json();

      sendOutput(data.id);
      //cleanup
      setPatientEmail("");
      setPatientName("");
      setAppointmentDate(null);
      setReferredBy("");
      onCloseButtonClick();
      callback(true);
    } catch (e) {
      console.log(e.message);
      callback(false);
    }
    // Send data to the backend via POST
  }

  function handleOk(value) {
    setAppointmentDate(value.$d.toUTCString());
  }

  function handleDateTimeChange(value) {}

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-wrapper">
      <div className="modal">
        <div className="create-patient-record-container">
          <div className="create-patient-record-close-button-wrapper">
            <button
              onClick={onCloseButtonClick}
              className="create-patient-record-close-button"
            >
              <img src={cancelImage} alt="cancel" />
            </button>
          </div>
          <div>Create new patient record</div>
          <TextField
            id="create_new_patient_name"
            label="Patient name"
            placeholder="Enter first and last name"
            value={patientName}
            handleTextChange={setPatientName}
          />
          <TextField
            id="create_new_patient_email"
            label="Patient email"
            placeholder="Enter email to which a link to patient form will be sent"
            value={patientEmail}
            handleTextChange={setPatientEmail}
          />
          <div className="create-patient-record-date-referred">
            <DateField
              id="appointment_date"
              label="Appointement Date"
              span="1"
              placeholder="Date and time"
              showTime={true}
              value={appointmentDate == null ? null : dayjs(appointmentDate)}
              handleChange={handleDateTimeChange}
              handleOk={handleOk}
            />
            <TextField
              id="reffered_by"
              label="Referred by"
              placeholder="Name of the dentist who reffered the patient"
              value={referredBy}
              handleTextChange={setReferredBy}
            />
          </div>
          <ActionButton
            id="create_patient_save"
            label="SAVE"
            span="1"
            handleClick={callPatientRecordCreate}
          />
        </div>
      </div>
    </div>,
    document.body
  );
}
