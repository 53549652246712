import { useState } from "react";
import { AdminDashboardContentLayout } from "./AdminDahsboardContentLayout";
import { AdminDashboardHeader } from "./AdminDashboardHeader";
import { AdminDashboardInnerLayout } from "./AdminDashboardInnerLayout";
import { CreateNewPatientRecord } from "./CreateNewPatientRecord";
import { SearchResults } from "./SearchResults";
import { TodaysAppointments } from "./TodaysAppointments";
import { WhiteBox } from "./WhiteBox";
import "./style/admin_dashboard.css";

export function AdminDashboard() {
  const [newPatientId, setNewPatientId] = useState(null);
  const [searchPatientRecords, setSearchPatientRecords] = useState([]);
  const [searchError, setSearchError] = useState(false);

  return (
    <div>
      <AdminDashboardHeader
        setSearchPatientRecords={setSearchPatientRecords}
        setSearchError={setSearchError}
      />
      <AdminDashboardContentLayout>
        <AdminDashboardInnerLayout>
          <WhiteBox rowSpan="2">
            <TodaysAppointments newPatientId={newPatientId} />
          </WhiteBox>
          <WhiteBox rowSpan="1">
            <CreateNewPatientRecord setNewPatientId={setNewPatientId} />
          </WhiteBox>
          <WhiteBox rowSpan="1">
            <SearchResults
              searchPatientRecords={searchPatientRecords}
              searchError={searchError}
            />
          </WhiteBox>
        </AdminDashboardInnerLayout>
      </AdminDashboardContentLayout>
    </div>
  );
}
