import dentistIllustration from "../../images/page_not_found.svg";
import "./page_not_found.css";
import { ActionButton } from "../Shared/ActionButton";

export function PageNotFound() {
  return (
    <div style={{ margin: "auto" }} className="container">
      <div className="pagenotfound-headers">
        <h1>
          WE CAN'T FIND <br />
          THIS PAGE
        </h1>
      </div>
      <div className="pagenotfound-illustration">
        <img
          src={dentistIllustration}
          alt="background gradient"
          className="qr-image"
          style={{ display: "block", margin: "auto" }}
        />
      </div>
      <div className="pagenotfound-instruction">
        <p>Please check if you have the right link to access your form.</p>
      </div>
      <ActionButton id="go_back" label="GO BACK" />
    </div>
  );
}
