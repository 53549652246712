import "./style/diagnosis.css";
import teeth from "../../../images/zubiClean.svg";
import { ActionButton } from "../../Shared/ActionButton";
import { ToothTab } from "../Exam/ToothTab";
import { useEffect, useState } from "react";

export function DiagnosisPresentation({
  patientRecord,
  selectedTooth,
  handlePatientRecordChange,
  setShowEdit,
  setSelectedTooth,
  teethState,
  setTeethState,
  pulpalOptions,
  periapicalOptions,
  nonodontogenicOptions,
  unexplainedOptions,
}) {
  const [diagnosisText, setDiagnosisText] = useState("");

  function handleClick() {
    setShowEdit(true);
  }

  useEffect(
    function () {
      function getPulpalOptionById(id) {
        return pulpalOptions.filter((el) => el.id === id)[0]?.name;
      }

      function getPeriapicalOptionById(id) {
        return periapicalOptions.filter((el) => el.id === id)[0]?.name;
      }

      function getNonodontogenicOptionById(id) {
        return nonodontogenicOptions.filter((el) => el.id === id)[0]?.name;
      }
      let pulpalDiagnosis = patientRecord.tooths?.[
        selectedTooth
      ]?.diagnosis?.pulpals?.reduce(
        (p, c, idx) =>
          idx === 0
            ? getPulpalOptionById(c.id)
            : p + ", " + getPulpalOptionById(c.id),
        ""
      );
      pulpalDiagnosis = pulpalDiagnosis == "" ? null : pulpalDiagnosis;
      let periapicalDiagnosis = patientRecord.tooths?.[
        selectedTooth
      ]?.diagnosis?.periapicals?.reduce(
        (p, c, idx) =>
          idx === 0
            ? getPeriapicalOptionById(c.id)
            : p + ", " + getPeriapicalOptionById(c.id),
        ""
      );
      periapicalDiagnosis =
        periapicalDiagnosis == "" ? null : periapicalDiagnosis;
      let nonOdontogenicDiagnosis = patientRecord.tooths?.[
        selectedTooth
      ]?.diagnosis?.non_odontogenics?.reduce(
        (p, c, idx) =>
          idx === 0
            ? getNonodontogenicOptionById(c.id)
            : p + ", " + getNonodontogenicOptionById(c.id),
        ""
      );
      nonOdontogenicDiagnosis =
        nonOdontogenicDiagnosis == "" ? null : nonOdontogenicDiagnosis;
      let unexplainedDiagnosis = patientRecord.tooths?.[selectedTooth]
        ?.diagnosis?.unexplained_at_this_time
        ? "Unexplained at this time"
        : null;

      if (pulpalDiagnosis != null && periapicalDiagnosis != null) {
        setDiagnosisText(pulpalDiagnosis + " and " + periapicalDiagnosis);
      } else if (nonOdontogenicDiagnosis != null) {
        setDiagnosisText(nonOdontogenicDiagnosis);
      } else if (unexplainedDiagnosis != null) {
        setDiagnosisText(unexplainedDiagnosis);
      } else {
        setDiagnosisText("No diagnosis set for this tooth yet");
      }
    },
    [
      patientRecord,
      selectedTooth,
      nonodontogenicOptions,
      periapicalOptions,
      pulpalOptions,
      unexplainedOptions,
    ]
  );

  return (
    <div className="diagnosis">
      <div className="diagnosis-title">
        TOOTH #{patientRecord.tooths?.[selectedTooth]?.number}
      </div>
      <div className="diagnosis-subtitle">
        You are viewing diagnosis for tooth #
        {patientRecord.tooths?.[selectedTooth]?.number}. To view or edit data
        for a different tooth, click on that tooth.{" "}
      </div>
      <ToothTab
        setSelectedTooth={setSelectedTooth}
        patientRecord={patientRecord}
        handlePatientRecordChange={handlePatientRecordChange}
        teethState={teethState}
        setTeethState={setTeethState}
      />
      <div className="diagnosis-text">
        {patientRecord.tooths?.[selectedTooth]?.diagnosis == null
          ? "No diagnosis set for this tooth yet"
          : `${diagnosisText}`}
      </div>
      <div className="diagnosis-action-button-container">
        <ActionButton
          id="edit_diagnosis"
          label="EDIT DIAGNOSIS"
          span="1"
          handleClick={handleClick}
        />
      </div>
    </div>
  );
}
