import createButton from "../../images/create_patient_record.png";
import { useModal } from "../useModal";
import { useAlert } from "../useAlert";
import { Alert } from "./Alert";
import { CreatePatientRecord } from "./CreatePatientRecord";
import { useState } from "react";

export function CreateNewPatientRecord({ setNewPatientId }) {
  const [isShowingModal, toggleModal] = useModal();
  const [isShowingAlert, toggleAlert] = useAlert();
  const [success, setSuccess] = useState(true);

  function callback(success) {
    setSuccess(success);
    toggleAlert();
  }

  return (
    <div className="create-new-patient-record">
      <CreatePatientRecord
        show={isShowingModal}
        onCloseButtonClick={toggleModal}
        sendOutput={setNewPatientId}
        callback={callback}
      />
      <Alert
        show={isShowingAlert}
        onCloseButtonClick={toggleAlert}
        success={success}
      />
      <button>
        <img
          src={createButton}
          alt="create patient record"
          onClick={toggleModal}
        />
      </button>
      <span>Create new patient record</span>
    </div>
  );
}
