import ReactDOM from "react-dom";
import cancelImageDark from "../../images/cancelDark.png";
import cancelImageWhite from "../../images/cancelWhite.png";

export function Alert({ show, onCloseButtonClick, success = true }) {
  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-wrapper">
      <div
        className="alert"
        style={
          success
            ? { backgroundColor: "#8EC9C7" }
            : { backgroundColor: "#FF4F6E" }
        }
      >
        <div
          className="alert-container"
          style={success ? {} : { color: "#fff" }}
        >
          <span>
            {success
              ? "New patient record successfully created!"
              : "Something went wrong, please try again!"}
          </span>

          <button
            onClick={onCloseButtonClick}
            className="create-patient-record-close-button"
          >
            <img
              src={success ? cancelImageDark : cancelImageWhite}
              alt="cancel"
            />
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
}
