import { SearchResultsContent } from "./SearchResultsContent";
import { SearchResultsHeader } from "./SearchResultsHeader";

export function SearchResults({ searchPatientRecords, searchError }) {
  return (
    <div className="search-results">
      <SearchResultsHeader />
      <SearchResultsContent
        searchPatientRecords={searchPatientRecords}
        searchError={searchError}
      />
    </div>
  );
}
