import { ActionButton } from "../Shared/ActionButton";
import { MultipleOptions } from "../Shared/MultipleOptions";
import { EndoRange } from "../Shared/EndoRange";
import { TextArea } from "../Shared/TextArea";
import { TextField } from "../Shared/TextField";
import { YesNo } from "../Shared/YesNo";
import "./style/patient_form.css";
import { useEffect, useState } from "react";

export default function CurrentIssue({
  toothSensitivityOptions,
  patientRecord,
  handlePatientRecordChange,
  handleActionButtonClicked,
  savePatientRecord,
}) {
  const [errorIds, setErrorIds] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function isEmpty(value) {
    return (
      value === null ||
      value === "" ||
      value === undefined ||
      value.length === 0
    );
  }

  function validateFields() {
    let newErrorIds = Object.assign({}, errorIds);
    isEmpty(patientRecord.patient_name)
      ? (newErrorIds.patient_name = true)
      : delete newErrorIds.patient_name;
    isEmpty(patientRecord.referred_by)
      ? (newErrorIds.referred_by = true)
      : delete newErrorIds.referred_by;
    isEmpty(patientRecord.chief_complaint)
      ? (newErrorIds.chief_complaint = true)
      : delete newErrorIds.chief_complaint;
    isEmpty(patientRecord.pain_at_the_moment)
      ? (newErrorIds.pain_at_the_moment = true)
      : delete newErrorIds.pain_at_the_moment;
    isEmpty(patientRecord.pain_past_two_weeks)
      ? (newErrorIds.pain_past_two_weeks = true)
      : delete newErrorIds.pain_past_two_weeks;
    isEmpty(patientRecord.pain_localized_to_specific_tooth)
      ? (newErrorIds.pain_localized_to_specific_tooth = true)
      : delete newErrorIds.pain_localized_to_specific_tooth;
    isEmpty(patientRecord.tooth_sensitive)
      ? (newErrorIds.tooth_sensitive = true)
      : delete newErrorIds.tooth_sensitive;
    isEmpty(patientRecord.tooth_sensitivities) && patientRecord?.tooth_sensitive
      ? (newErrorIds.tooth_sensitivities = true)
      : delete newErrorIds.tooth_sensitivities;
    setErrorIds(newErrorIds);
    return newErrorIds;
  }

  function handleNextTabClicked() {
    const newErrorIds = validateFields();
    if (
      Object.keys(newErrorIds).length === 0 &&
      newErrorIds.constructor === Object
    ) {
      savePatientRecord(function () {
        handleActionButtonClicked(2);
      });
    } else {
      return;
    }
  }

  function handlePatientNameChange(name) {
    const newPatientRecord = { ...patientRecord, patient_name: name };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleReferredByChange(referredBy) {
    const newPatientRecord = { ...patientRecord, referred_by: referredBy };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleChiefComplaintChange(chiefComplaint) {
    const newPatientRecord = {
      ...patientRecord,
      chief_complaint: chiefComplaint,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handlePainAtTheMomentChange(painAtTheMoment) {
    const newPatientRecord = {
      ...patientRecord,
      pain_at_the_moment: painAtTheMoment,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handlePainPastTwoWeeksChange(painPastTwoWeeks) {
    const newPatientRecord = {
      ...patientRecord,
      pain_past_two_weeks: painPastTwoWeeks,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handlePainLocalizedToSpecificToothChange(painSetting) {
    const newPatientRecord = {
      ...patientRecord,
      pain_localized_to_specific_tooth:
        painSetting === "none" ? null : painSetting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleToothSensitiveChange(painSetting) {
    let newPatientRecord = {
      ...patientRecord,
      tooth_sensitive:
        painSetting === "none" ? null : painSetting === "yes" ? true : false,
    };
    if (painSetting !== "yes") {
      newPatientRecord = {
        ...newPatientRecord,
        tooth_sensitivities: [],
      };
    }
    handlePatientRecordChange(newPatientRecord);
  }

  function handleToothSensitivitiesChange(arrayOfSensitivities) {
    const newPatientRecord = {
      ...patientRecord,
      tooth_sensitivities: arrayOfSensitivities.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handlePatientRecordChange(newPatientRecord);
  }

  return (
    <div className="current-issue">
      <form className="current-issue-form">
        <TextField
          id="patient_name"
          label="Patient Name"
          placeholder="Enter your first and last name"
          value={patientRecord.patient_name}
          span="1"
          handleTextChange={handlePatientNameChange}
          error={errorIds?.patient_name ?? false}
        />
        <TextField
          id="referred_by"
          label="Referred by"
          placeholder="Name of the dentist who reffered you to us"
          value={patientRecord.referred_by}
          span="1"
          handleTextChange={handleReferredByChange}
          error={errorIds?.referred_by ?? false}
        />
        <TextArea
          id="chief_complaint"
          label="Chief complaint"
          placeholder="Describe the issue you are experiencing right now. What is the nature of your visit?"
          value={patientRecord.chief_complaint ?? ""}
          span="2"
          handleTextChange={handleChiefComplaintChange}
          error={errorIds?.chief_complaint ?? false}
        />

        <EndoRange
          id="pain_present"
          label="How bad is your pain at the moment?"
          span="1"
          min={0}
          max={10}
          minLabel={"No Pain (0)"}
          midLabel={"Moderate (5)"}
          maxLabel={"Excruciating (10)"}
          value={patientRecord.pain_at_the_moment}
          handleChange={handlePainAtTheMomentChange}
          error={errorIds?.pain_at_the_moment ?? false}
        />
        <EndoRange
          id="pain_past"
          label="How was your pain within the past two weeks?"
          span="1"
          min={0}
          max={10}
          minLabel={"No Pain (0)"}
          midLabel={"Moderate (5)"}
          maxLabel={"Excruciating (10)"}
          value={patientRecord.pain_past_two_weeks}
          handleChange={handlePainPastTwoWeeksChange}
          error={errorIds?.pain_past_two_weeks ?? false}
        />
        <YesNo
          id="pain_localized"
          label="Is the pain localized to a specific tooth?"
          span="2"
          setting={patientRecord.pain_localized_to_specific_tooth}
          handleChange={handlePainLocalizedToSpecificToothChange}
          error={errorIds?.pain_localized_to_specific_tooth ?? false}
        />
        <YesNo
          id="tooth_sensitive"
          label="Is your tooth sensitive?"
          span="2"
          setting={patientRecord.tooth_sensitive}
          handleChange={handleToothSensitiveChange}
          error={errorIds?.tooth_sensitive ?? false}
        />

        <MultipleOptions
          id="tooth_sensitive_to"
          label="The tooth is sensitive to (click all that apply)"
          span="2"
          options={toothSensitivityOptions}
          selectedIds={
            patientRecord.tooth_sensitivities == null
              ? []
              : patientRecord.tooth_sensitivities.map((el) => el.id)
          }
          handleChange={handleToothSensitivitiesChange}
          disabled={
            patientRecord.tooth_sensitive == null
              ? true
              : patientRecord.tooth_sensitive
              ? false
              : true
          }
          error={errorIds?.tooth_sensitivities ?? false}
        />
        <ActionButton
          id="to_dental_history"
          label="SAVE AND CONTINUE TO DENTAL HISTORY"
          span="2"
          handleClick={handleNextTabClicked}
        />
      </form>
    </div>
  );
}
