import "./style/assistant_form_tab_bar.css";

export function AssistantFormTabBar({ currentTab, handleClick }) {
  return (
    <div className="assistant-form-tab-bar">
      <AssistantFormTabBarButton
        current={currentTab === 1}
        handleClick={() => {
          handleClick(1);
        }}
      >
        EXAM
      </AssistantFormTabBarButton>
      &#x2022;
      <AssistantFormTabBarButton
        current={currentTab === 2}
        handleClick={() => {
          handleClick(2);
        }}
      >
        DIAGNOSIS
      </AssistantFormTabBarButton>
      &#x2022;
      <AssistantFormTabBarButton
        current={currentTab === 3}
        handleClick={() => {
          handleClick(3);
        }}
      >
        TREATMENT
      </AssistantFormTabBarButton>
    </div>
  );
}

function AssistantFormTabBarButton({ current, children, handleClick }) {
  return (
    <button
      className={
        current
          ? "assistant-form-tab-bar-button assistant-form-tab-bar-button-current"
          : "assistant-form-tab-bar-button"
      }
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      {children}
    </button>
  );
}
