export function ClinicalTestingTabSelect({
  tabSelected,
  setTabSelected,
  patientRecord,
  selectedTab,
  selectedTooth,
}) {
  return (
    <div className="clinical-test-tab-select">
      <button
        className={
          tabSelected === 1
            ? "clinical-test-tab-button-selected"
            : "clinical-test-tab-button"
        }
        onClick={(e) => setTabSelected(1)}
      >
        {patientRecord.tooths[selectedTooth]?.clinical_testings?.[0]
          ?.tooth_number == null
          ? "#"
          : patientRecord.tooths[selectedTooth].clinical_testings?.[0]
              .tooth_number}
      </button>
      <button
        className={
          tabSelected === 2
            ? "clinical-test-tab-button-selected"
            : "clinical-test-tab-button"
        }
        onClick={(e) => setTabSelected(2)}
      >
        {patientRecord.tooths[selectedTooth]?.clinical_testings?.[1]
          ?.tooth_number == null
          ? "#"
          : patientRecord.tooths[selectedTooth].clinical_testings?.[1]
              .tooth_number}
      </button>
      <button
        className={
          tabSelected === 3
            ? "clinical-test-tab-button-selected"
            : "clinical-test-tab-button"
        }
        onClick={(e) => setTabSelected(3)}
      >
        {patientRecord.tooths[selectedTooth]?.clinical_testings?.[2]
          ?.tooth_number == null
          ? "#"
          : patientRecord.tooths[selectedTooth].clinical_testings?.[2]
              .tooth_number}
      </button>
      <button
        className={
          tabSelected === 4
            ? "clinical-test-tab-button-selected"
            : "clinical-test-tab-button"
        }
        onClick={(e) => setTabSelected(4)}
      >
        {patientRecord.tooths[selectedTooth]?.clinical_testings?.[3]
          ?.tooth_number == null
          ? "#"
          : patientRecord.tooths[selectedTooth].clinical_testings?.[3]
              .tooth_number}
      </button>
      <div className="clinical-test-tab-divider"></div>
    </div>
  );
}
