import dividerLine from "../../images/divider_line.svg";
import TabButton from "./TabButton.js";
import "./style/tab_section.css";

export default function TabSection({
  current,
  filled,
  handleTabPressed,
  handleTabPressedPlus,
  handleTabPressedMinus,
}) {
  return (
    <>
      <div className="tab-select-mobile">
        {current === 1 ? (
          <>
            <div className="tab-section-container">
              <span></span>
              <span className="headingTab">1. Current issue</span>
              <TabButton
                current={current === 1}
                filled={filled.reduce(
                  (fill, filledOption) => fill || filledOption === 1,
                  false
                )}
                buttonOrder="1"
                onClick={handleTabPressedPlus}
              >
                {">"}
              </TabButton>
            </div>
          </>
        ) : (
          <></>
        )}
        {current === 2 ? (
          <>
            <div className="tab-section-container">
              <TabButton
                current={current === 2}
                filled={filled.reduce(
                  (fill, filledOption) => fill || filledOption === 2,
                  false
                )}
                buttonOrder="2"
                onClick={handleTabPressedMinus}
              >
                {"<"}
              </TabButton>
              <span className="headingTab">2. Dental history</span>
              <TabButton
                current={current === 2}
                filled={filled.reduce(
                  (fill, filledOption) => fill || filledOption === 2,
                  false
                )}
                buttonOrder="2"
                onClick={handleTabPressedPlus}
              >
                {">"}
              </TabButton>
            </div>
          </>
        ) : (
          <></>
        )}
        {current === 3 ? (
          <>
            <div className="tab-section-container">
              <TabButton
                current={current === 3}
                filled={filled.reduce(
                  (fill, filledOption) => fill || filledOption === 3,
                  false
                )}
                buttonOrder="3"
                onClick={handleTabPressedMinus}
              >
                {"<"}
              </TabButton>
              <span className="headingTab">3. Medical History</span>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="tab-select">
        <TabButton
          current={current === 1}
          filled={filled.reduce(
            (fill, filledOption) => fill || filledOption === 1,
            false
          )}
          buttonOrder="1"
          onClick={handleTabPressed}
        >
          1. Current issue
        </TabButton>
        <img
          src={dividerLine}
          alt="tab-divider line"
          className="tab-divider-line"
        />
        <TabButton
          current={current === 2}
          filled={filled.reduce(
            (fill, filledOption) => fill || filledOption === 2,
            false
          )}
          buttonOrder="2"
          onClick={handleTabPressed}
        >
          2. Dental history
        </TabButton>
        <img
          src={dividerLine}
          alt="tab-divider line"
          className="tab-divider-line"
        />
        <TabButton
          current={current === 3}
          filled={filled.reduce(
            (fill, filledOption) => fill || filledOption === 3,
            false
          )}
          buttonOrder="3"
          onClick={handleTabPressed}
        >
          3. Medical History
        </TabButton>
      </div>
    </>
  );
}
