import patientDone from "../../images/patient_done.png";
import "./patient_finished.css";
import { ActionButton } from "../Shared/ActionButton";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export function PatientFinished() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ margin: "auto" }} className="container">
      <div className="patient-finished-headers">
        <h1>THANK YOU</h1>
        <h2>see you at MicroSurgical Endo</h2>
      </div>
      <div className="patient-finished-illustration">
        <img
          src={patientDone}
          alt="background gradient"
          className="qr-image"
          style={{ display: "block" }}
        />
      </div>
      <div className="patient-finished-instruction">
        <p>
          We look forward to helping you with you endodontic needs. <br />
          If you need to change your answers, you can go back and edit the form.
        </p>
      </div>
      <div className={`span-2 button-action-container`}>
        <Link to="/patient">
          <button id="fill_the_form" className="button-action">
            GO BACK AND EDIT
          </button>
        </Link>
      </div>
      {/* <ActionButton id="fill_the_form" label="GO BACK AND EDIT" /> */}
    </div>
  );
}
