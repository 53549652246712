import "./style/multiple_options.css";

export function MultipleOptions({
  id,
  label,
  span,
  options,
  selectedIds,
  handleChange,
  noneIndicator,
  disabled,
  isExclusive = false,
  error = false,
}) {
  function handleOnClick(id) {
    if (isExclusive) {
      handleExclusiveOnClick(id);
      return;
    }
    if (selectedIds.some((selId) => selId === 123456789)) {
      handleChange([]);
      return;
    }
    if (id === 123456789) {
      handleChange([123456789]);
      return;
    }
    if (selectedIds.some((selId) => selId === id)) {
      const newSelectedIds = selectedIds.filter((selId) => selId !== id);
      handleChange(newSelectedIds);
    } else {
      const newSelectedIds = [...selectedIds, id].filter(
        (selId) => selId !== 123456789
      );
      handleChange(newSelectedIds);
    }
  }

  function handleExclusiveOnClick(id) {
    if (selectedIds.some((selId) => selId === id)) {
      const newSelectedIds = [];
      handleChange(newSelectedIds);
    } else {
      const newSelectedIds = [id];
      handleChange(newSelectedIds);
    }
  }

  const noneOption = { id: 123456789, name: "None of these" };

  return (
    <div className={`span-${span} multiple-options`}>
      <div className={disabled ? "form-label-disabled" : "form-label"}>
        {label}
      </div>
      <MultipleOptionButtons
        id={id}
        options={noneIndicator == null ? options : [...options, noneOption]}
        selectedIds={selectedIds}
        handleOnClick={handleOnClick}
        disabled={disabled}
        error={error}
      />
    </div>
  );
}
function MultipleOptionButtons({
  options,
  selectedIds,
  handleOnClick,
  disabled,
  error = false,
}) {
  return (
    <div>
      {options.map((option) => (
        <button
          key={option.id}
          className={
            selectedIds == null
              ? error
                ? "button-multiple-option error"
                : "button-multiple-option"
              : selectedIds.some((id) => id === option.id)
              ? "button-multiple-option button-multiple-selected"
              : error
              ? "button-multiple-option-error"
              : "button-multiple-option"
          }
          onClick={(e) => {
            e.preventDefault();
            handleOnClick(option.id);
          }}
          disabled={disabled}
        >
          {option.name}
        </button>
      ))}
    </div>
  );
}
