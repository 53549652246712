import { Logo } from "./Logo";
import { ProfileLogout } from "../Shared/ProfileLogout";
import { Searchbar } from "./Searchbar";

export function AdminDashboardHeader({
  setSearchPatientRecords,
  setSearchError,
}) {
  return (
    <header className="admin-dashboard-header">
      <div>
        <Logo />
      </div>
      <div>
        <Searchbar
          setSearchPatientRecords={setSearchPatientRecords}
          setSearchError={setSearchError}
        />
      </div>
      <div>
        <ProfileLogout />
      </div>
    </header>
  );
}
