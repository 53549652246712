import "./style/short_text.css";

export function ShortText({
  id,
  label,
  span,
  value,
  disabled,
  error,
  handleTextChange,
}) {
  return (
    <div className={`span-${span} short-text`}>
      <label className="form-label">{label}</label>
      <input
        type="text"
        className={error ? "short-text-input error" : "short-text-input"}
        id={id}
        disabled={disabled}
        value={value}
        onChange={(e) => handleTextChange(e.target.value)}
      ></input>
    </div>
  );
}
