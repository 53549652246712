import { YesNoButtons } from "./YesNoButtons";
import "./style/yes_no.css";

export function YesNo({
  id,
  label,
  span,
  error,
  disabled,
  setting,
  handleChange,
}) {
  return (
    <div className={`span-${span} yes-no`}>
      <label
        dangerouslySetInnerHTML={{ __html: label }}
        className="form-label"
      ></label>
      <YesNoButtons
        id={id}
        setting={setting == null ? "none" : setting ? "yes" : "no"}
        setSetting={handleChange}
        error={error}
        disabled={disabled}
      />
    </div>
  );
}
