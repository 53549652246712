import calendarIcon from "../../images/calendar_icon.png";

export function TodaysAppointmentsHeader() {
  return (
    <div className="todays-appointments-header">
      <img src={calendarIcon} alt="calendar icon" />
      <span>Today's Appointments</span>
      <div className="admin-dashboard-header-divider"></div>
    </div>
  );
}
