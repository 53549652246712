import { Link } from "react-router-dom";
import { ActionButton } from "../Shared/ActionButton";
import { MultipleOptions } from "../Shared/MultipleOptions";
import { TextArea } from "../Shared/TextArea";
import { YesNo } from "../Shared/YesNo";
import YesNoText from "../Shared/YesNoText";
import "./style/patient_form.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function MedicalHistory({
  medicationsOptions,
  allergicOptions,
  patientRecord,
  handlePatientRecordChange,
  savePatientRecord,
}) {
  const [errorIds, setErrorIds] = useState({});
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();

  function isEmpty(value) {
    return (
      value === null ||
      value === "" ||
      value === undefined ||
      value.length === 0
    );
  }

  function isNone(optionsIds, optionsSet) {
    if (optionsIds == null) return false;
    const idOfNoneInTheOptionsSet = optionsSet.filter(
      (el) => el.name === "None"
    )?.[0]?.id;

    if (optionsIds.some((el) => el.id === idOfNoneInTheOptionsSet)) {
      return true;
    } else {
      return false;
    }
  }

  function valdte(
    field_name,
    newErrorIds,
    predicate = function (f_name) {
      return isEmpty(patientRecord[f_name]);
    }
  ) {
    predicate(field_name)
      ? (newErrorIds[field_name] = true)
      : delete newErrorIds[field_name];
  }

  function validateFields() {
    let newErrorIds = Object.assign({}, errorIds);
    valdte("heart_disease", newErrorIds);
    valdte("high_blood_pressure", newErrorIds);
    valdte("blood_related_disease", newErrorIds);
    valdte("infectious_disease", newErrorIds);
    valdte("immune_system_problem", newErrorIds);
    valdte("gland_disease", newErrorIds);
    valdte("operations_or_surgeries", newErrorIds);
    valdte("antibiotics_required", newErrorIds);
    valdte("medications", newErrorIds);
    valdte("taking_other_medications", newErrorIds);
    valdte("taking_other_medications_list", newErrorIds, function (f_name) {
      return (
        isEmpty(patientRecord[f_name]) &&
        patientRecord?.taking_other_medications
      );
    });
    valdte("allergens", newErrorIds);
    valdte("allergic_to_other_medications", newErrorIds);
    valdte(
      "allergic_to_other_medications_list",
      newErrorIds,
      function (f_name) {
        return (
          isEmpty(patientRecord[f_name]) &&
          patientRecord?.allergic_to_other_medications
        );
      }
    );
    valdte("other_medical_problems", newErrorIds);
    valdte("other_medical_problems_list", newErrorIds, function (f_name) {
      return (
        isEmpty(patientRecord[f_name]) && patientRecord?.other_medical_problems
      );
    });
    setErrorIds(newErrorIds);
    return newErrorIds;
  }

  function handleSave() {
    const newErrorIds = validateFields();
    if (
      Object.keys(newErrorIds).length === 0 &&
      newErrorIds.constructor === Object
    ) {
      savePatientRecord(function () {
        console.log("save");
        if (isAuthenticated) {
          navigate(`/assistant_form/${patientRecord.id}`);
        } else {
          navigate(`/patient_done/${patientRecord.token}`);
        }
      });
    } else {
      return;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleHeartProblemsChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      heart_disease:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleHighBloodPressureChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      high_blood_pressure:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleBloodRelatedDiseaseChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      blood_related_disease:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleInfectiousDiseaseChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      infectious_disease:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleImmuneSystemChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      immune_system_problem:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleGlandDiseaseChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      gland_disease:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleSurgeriesChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      operations_or_surgeries:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleAntibioticsRequiredChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      antibiotics_required:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleMedicationsChange(array) {
    const newPatientRecord = {
      ...patientRecord,
      medications: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleTakingOtherMedicationsChange(setting, text) {
    const newPatientRecord = {
      ...patientRecord,
      taking_other_medications:
        setting === "none" ? null : setting === "yes" ? true : false,
      taking_other_medications_list: text,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleAllergensChange(array) {
    const newPatientRecord = {
      ...patientRecord,
      allergens: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleAllergicToOtherMedicationsChange(setting, text) {
    const newPatientRecord = {
      ...patientRecord,
      allergic_to_other_medications:
        setting === "none" ? null : setting === "yes" ? true : false,
      allergic_to_other_medications_list: text,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handlePregnantOrNursingChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      pregnant_or_nursing:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleOtherMedicalProblemsChange(setting, text) {
    const newPatientRecord = {
      ...patientRecord,
      other_medical_problems:
        setting === "none" ? null : setting === "yes" ? true : false,
      other_medical_problems_list: text,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleAdditionalNotesChange(text) {
    const newPatientRecord = {
      ...patientRecord,
      additional_notes: text,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  return (
    <div className="dental-history">
      <form className="dental-history-form">
        <YesNo
          id="heart_problems"
          label="Do you have or have you ever had <b>Heart Disease, Murmurs, Heart Attack</b>, or any heart related problems?"
          span="2"
          setting={patientRecord.heart_disease}
          handleChange={handleHeartProblemsChange}
          error={errorIds?.heart_disease ?? false}
        />
        <YesNo
          id="high_blood_pressure"
          label="Do you have <b>High blood pressure</b>?"
          span="2"
          setting={patientRecord.high_blood_pressure}
          handleChange={handleHighBloodPressureChange}
          error={errorIds?.high_blood_pressure ?? false}
        />
        <YesNo
          id="bleedin_bruising"
          label="Do you have any bleeding/bruising or other blood related diseases?"
          span="2"
          setting={patientRecord.blood_related_disease}
          handleChange={handleBloodRelatedDiseaseChange}
          error={errorIds?.blood_related_disease ?? false}
        />
        <YesNo
          id="infectious_diseases"
          label="Do you have any infectious diseases like <b>Hepatitis, TB, HIV/AIDS</b>, etc?"
          span="2"
          setting={patientRecord.infectious_disease}
          handleChange={handleInfectiousDiseaseChange}
          error={errorIds?.infectious_disease ?? false}
        />
        <YesNo
          id="immune_system_problems"
          label="Do you have <b>Asthma, Hay Fever, Sinusitis</b>, or other immune system problems?"
          span="2"
          setting={patientRecord.immune_system_problem}
          handleChange={handleImmuneSystemChange}
          error={errorIds?.immune_system_problem ?? false}
        />
        <YesNo
          id="endocrine_diseases"
          label="Do you have <b>Diabetes, Thyroid</b>, or other gland related diseases?"
          span="2"
          setting={patientRecord.gland_disease}
          handleChange={handleGlandDiseaseChange}
          error={errorIds?.gland_disease ?? false}
        />
        <YesNo
          id="surgeries"
          label="Have you had any major operations or surgeries?"
          span="2"
          setting={patientRecord.operations_or_surgeries}
          handleChange={handleSurgeriesChange}
          error={errorIds?.operations_or_surgeries ?? false}
        />
        <YesNo
          id="require_antibiotic"
          label="Do you require antibiotic prophylaxis prior to your dental visit?"
          span="2"
          setting={patientRecord.antibiotics_required}
          handleChange={handleAntibioticsRequiredChange}
          error={errorIds?.antibiotics_required ?? false}
        />
        <MultipleOptions
          id="medications"
          label="Do you take any of these medications?"
          span="2"
          options={medicationsOptions}
          selectedIds={
            patientRecord.medications == null
              ? []
              : patientRecord.medications.map((el) => el.id)
          }
          handleChange={handleMedicationsChange}
          noneIndicator={true}
          error={errorIds?.medications ?? false}
        />
        <YesNoText
          id="other_medications"
          label="Are you currently taking other medications than those listed above?"
          span="2"
          placeholder={"If yes, please list which ones"}
          setting={patientRecord.taking_other_medications}
          settingText={patientRecord.taking_other_medications_list ?? ""}
          handleChange={handleTakingOtherMedicationsChange}
          error={errorIds?.taking_other_medications ?? false}
          errorText={errorIds?.taking_other_medications_list ?? false}
        ></YesNoText>
        <MultipleOptions
          id="allergies"
          label="Are you allergic to any of the following?"
          span="2"
          options={allergicOptions}
          selectedIds={
            patientRecord.allergens == null
              ? []
              : patientRecord.allergens.map((el) => el.id)
          }
          handleChange={handleAllergensChange}
          noneIndicator={true}
          error={errorIds?.allergens ?? false}
        />
        <YesNoText
          id="other_allergies"
          label="Are you allergic to any other medication or substance?"
          span="2"
          placeholder={"If yes, please list which ones"}
          setting={patientRecord.allergic_to_other_medications}
          settingText={patientRecord.allergic_to_other_medications_list ?? ""}
          handleChange={handleAllergicToOtherMedicationsChange}
          error={errorIds?.allergic_to_other_medications ?? false}
          errorText={errorIds?.allergic_to_other_medications_list ?? false}
        />
        <YesNoText
          id="other_medical_problems"
          label="Do you have any other medical problems not listed above?"
          span="2"
          placeholder={"If yes, please list which ones"}
          setting={patientRecord.other_medical_problems}
          settingText={patientRecord.other_medical_problems_list ?? ""}
          handleChange={handleOtherMedicalProblemsChange}
          error={errorIds?.other_medical_problems ?? false}
          errorText={errorIds?.other_medical_problems_list ?? false}
        />
        <YesNo
          id="pregnant_nursing"
          label="Women only: Are you pregnant/nursing?"
          span="2"
          setting={patientRecord.pregnant_or_nursing}
          handleChange={handlePregnantOrNursingChange}
        />

        <TextArea
          id="patient_additional_notes"
          label="Additional notes - fills out dental office"
          placeholder="Add any additional information"
          value={patientRecord.additional_notes ?? ""}
          span="2"
          handleTextChange={handleAdditionalNotesChange}
        />
        {/* <div className={`span-2 button-action-container`}>
          <Link to="/patient_done">
            <button id="to_finish" className="button-action">
              SAVE AND FINISH
            </button>
          </Link>
        </div> */}
        <ActionButton
          id="to_finish"
          label="SAVE AND FINISH"
          span="2"
          handleClick={handleSave}
        />
      </form>
    </div>
  );
}
