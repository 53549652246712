import "./style/action_button.css";

export function ActionButton({ id, label, span, handleClick }) {
  return (
    <div className={`span-${span} button-action-container`}>
      <button
        id={id}
        className="button-action"
        onClick={(e) => {
          e.preventDefault();
          if (handleClick === undefined) return;
          handleClick();
        }}
      >
        {label}
      </button>
    </div>
  );
}
