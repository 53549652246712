import "./style/diagnosis.css";
import { DiagnosisPresentation } from "./DiagnosisPresentation";
import { DiagnosisEdit } from "./DiagnosisEdit";
import { useEffect, useState } from "react";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function Diagnosis({
  patientRecord,
  selectedTooth,
  handlePatientRecordChange,
  setSelectedTooth,
  teethState,
  setTeethState,
  savePatientRecord,
}) {
  const [showEdit, setShowEdit] = useState(false);

  const [pulpalOptions, setPulpalOptions] = useState([]);
  const [periapicalOptions, setPeriapicalOptions] = useState([]);
  const [nonodontogenicOptions, setNonodontogenicOptions] = useState([]);
  const unexplainedOptions = [{ id: 1, name: "Unexplained at this time" }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(function () {
    async function getPulpalOptions() {
      const res = await fetch(`${rootURL}/pulpals`);
      const data = await res.json();
      setPulpalOptions(data);
    }
    getPulpalOptions();
  }, []);

  useEffect(function () {
    async function getPeriapicalOptions() {
      const res = await fetch(`${rootURL}/periapicals`);
      const data = await res.json();
      setPeriapicalOptions(data);
    }
    getPeriapicalOptions();
  }, []);

  useEffect(function () {
    async function getNonodontogenicOptions() {
      const res = await fetch(`${rootURL}/non_odontogenics`);
      const data = await res.json();
      setNonodontogenicOptions(data);
    }
    getNonodontogenicOptions();
  }, []);

  return (
    <>
      {!showEdit && (
        <DiagnosisPresentation
          selectedTooth={selectedTooth}
          patientRecord={patientRecord}
          handlePatientRecordChange={handlePatientRecordChange}
          setShowEdit={setShowEdit}
          setSelectedTooth={setSelectedTooth}
          teethState={teethState}
          setTeethState={setTeethState}
          pulpalOptions={pulpalOptions}
          periapicalOptions={periapicalOptions}
          nonodontogenicOptions={nonodontogenicOptions}
          unexplainedOptions={unexplainedOptions}
        />
      )}
      {showEdit && (
        <DiagnosisEdit
          selectedTooth={selectedTooth}
          patientRecord={patientRecord}
          handlePatientRecordChange={handlePatientRecordChange}
          setShowEdit={setShowEdit}
          pulpalOptions={pulpalOptions}
          periapicalOptions={periapicalOptions}
          nonodontogenicOptions={nonodontogenicOptions}
          unexplainedOptions={unexplainedOptions}
          savePatientRecord={savePatientRecord}
        />
      )}
    </>
  );
}
