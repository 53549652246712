import { ClinicalTestingTabSelect } from "./ClinicalTestTabSelect";
import { TextField } from "../../../Shared/TextField";
import "./style/clinical_test.css";
import { ClinicalTestMatrix } from "./ClinicalTestMatrix";
import { useState } from "react";

export function ClinicalTest({
  patientRecord,
  handlePatientRecordChange,
  selectedTooth,
}) {
  const [tabSelected, setTabSelected] = useState(1);

  function handleClinicalTestingDataChange(newClinicalTestingData) {
    let newClinicalTesting = null;

    if (
      patientRecord.tooths[selectedTooth]?.clinical_testings == null ||
      patientRecord.tooths[selectedTooth]?.clinical_testings.length < 4
    ) {
      const initialClinicalTesting = [
        { tooth_number: null },
        { tooth_number: null },
        { tooth_number: null },
        { tooth_number: null },
      ];
      newClinicalTesting = initialClinicalTesting.map((ct, index) => {
        return index === tabSelected - 1 ? newClinicalTestingData : ct;
      });
    } else {
      newClinicalTesting = patientRecord.tooths[
        selectedTooth
      ].clinical_testings.map((ct, index) =>
        index === tabSelected - 1 ? newClinicalTestingData : ct
      );
    }
    console.log("NEW CLINICAL TESTING:\n" + JSON.stringify(newClinicalTesting));
    handleClinicalTestingChange(newClinicalTesting);
  }

  function handleClinicalTestingChange(newClinicalTesting) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      clinical_testings: newClinicalTesting,
    };
    const newTooths = patientRecord.tooths.map((t) =>
      t.number === newToothData.number ? newToothData : t
    );
    const newPatientRecord = {
      ...patientRecord,
      tooths: newTooths,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleToothNumberChange(text) {
    let initialClinicalTestingItem = { tooth_number: null };
    const newClinicalTestingData = {
      ...(patientRecord.tooths[selectedTooth]?.clinical_testings?.[
        tabSelected - 1
      ] ?? initialClinicalTestingItem),
      tooth_number: text === "" ? null : text,
    };
    console.log(
      "NEW CLINICAL TESTING DATA:\n" + JSON.stringify(newClinicalTestingData)
    );
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  return (
    <div className="clinical-test-wrapper">
      <div className="clinical-test">
        <div className="clinical-test-title">Clinical Testing</div>
        <ClinicalTestingTabSelect
          selectedTooth={selectedTooth}
          patientRecord={patientRecord}
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
        />
        <TextField
          id="clinical_test_tooth_no"
          label="Tooth number"
          placeholder="#"
          value={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.tooth_number ?? ""
          }
          handleTextChange={handleToothNumberChange}
        />
        <ClinicalTestMatrix
          tabSelected={tabSelected}
          selectedTooth={selectedTooth}
          patientRecord={patientRecord}
          handlePatientRecordChange={handlePatientRecordChange}
        />
      </div>
    </div>
  );
}
