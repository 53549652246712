import { useEffect, useState } from "react";
import searchIcon from "../../images/search_icon.png";
import { useAuth0 } from "@auth0/auth0-react";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function Searchbar({ setSearchPatientRecords, setSearchError }) {
  const [query, setQuery] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  //const [searchPatientRecords, setSearchPatientRecords] = useState([]);

  useEffect(
    function () {
      const controller = new AbortController();
      async function queryPatients() {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              // audience: `EndoAPIURL`,
              // scope: "openid profile email offline_access read:current_user",
            },
          });

          const headers = { Authorization: `Bearer ${accessToken}` };
          const res = await fetch(
            `${rootURL}/patient_records_by_patient_name?patient_name=${query}`,
            { headers: headers, signal: controller.signal }
          );
          const data = await res.json();

          setSearchPatientRecords(data);
          setSearchError(false);
          if (data.length === 0) {
            setSearchError(true);
          }
        } catch (e) {
          if (e.name !== "AbortError") {
            console.log(e.message);
          }
        }
      }
      if (query.length === 0) {
        setSearchPatientRecords([]);
        return;
      }
      queryPatients();
      return function () {
        controller.abort();
      };
    },
    [query, getAccessTokenSilently, setSearchPatientRecords]
  );

  return (
    <div className="searchbar">
      <form
        className="searchbar-form"
        onSubmit={(e) => {
          document.activeElement.blur();
          e.preventDefault();
        }}
      >
        <div>
          <img src={searchIcon} alt="search icon" className="search-icon" />
        </div>
        <div>
          <input
            id="search_text"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="search-text-field"
            placeholder="Search by patient's name"
          />
        </div>
      </form>
    </div>
  );
}
