import noPatientIllustration from "../../images/no-patient-illustration.png";

export function SearchResultsNoPatient({ searchError }) {
  return (
    <div className="search-results-no-patient">
      {searchError && (
        <p className="error">
          No patient by that name. Please check your spelling.
        </p>
      )}
      <div className="search-results-no-patient-content">
        <img src={noPatientIllustration} alt="illustration for no patient" />
      </div>
    </div>
  );
}
