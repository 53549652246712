import { TodaysAppointmentsHeader } from "./TodaysAppointementsHeader";
import { TodaysAppointmentsContent } from "./TodaysAppointmentsContent";
import { TodaysAppointmentsRow } from "./TodaysAppointmentsRow";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function TodaysAppointments({ newPatientId }) {
  const { getAccessTokenSilently } = useAuth0();
  const [patientsToday, setPatientsToday] = useState([]);

  useEffect(
    function () {
      async function getPatientsData() {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              // audience: `EndoAPIURL`,
              // scope: "openid profile email offline_access read:current_user",
            },
          });

          const headers = { Authorization: `Bearer ${accessToken}` };
          const res = await fetch(
            `${rootURL}/patient_records_by_appointment_date`,
            { headers }
          );
          const data = await res.json();
          setPatientsToday(data);
        } catch (e) {
          console.log(e.message);
        }
      }
      getPatientsData();
    },
    [getAccessTokenSilently, newPatientId]
  );

  return (
    <div className="todays-appointments">
      <TodaysAppointmentsHeader />
      <TodaysAppointmentsContent>
        {patientsToday.length === 0 ? (
          <p></p>
        ) : (
          patientsToday.map((el) => (
            <TodaysAppointmentsRow patientRecord={el} key={el.id} />
          ))
        )}
      </TodaysAppointmentsContent>
    </div>
  );
}
