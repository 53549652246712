export const dummyPatientRecord = {
  patient_name: "",
  referred_by: "",
  chief_complaint: "",
  pain_at_the_moment: null,
  pain_past_two_weeks: null,
  pain_localized_to_specific_tooth: null,
  tooth_sensitive: null,
  clench_or_grind_teeth: null,
  tmj_tmd_jaw_joint_problems: null,
  heart_disease: null,
  high_blood_pressure: null,
  blood_related_disease: null,
  infectious_disease: null,
  immune_system_problem: null,
  gland_disease: null,
  operations_or_surgeries: null,
  antibiotics_required: null,
  mirko: null,
  allergens: [],
  tooth_sensitivities: [],
  tooths: [
    {
      number: 11,
      image: "http://image.png",
      extra_oral_exam_notes: null,
      oral_cancer_screening_done: true,
      radiographic_findings_notes: "It's ok",
      clinical_testing: [
        {
          tooth_number: null,
          probing: null,
          cold: null,
          ept: null,
          hot: null,
          percussion: null,
          palpation: null,
          mobility: null,
          crack: null,
          restoration_defects: null,
          cbct: null,
        },
        {
          tooth_number: null,
          probing: null,
          cold: null,
          ept: null,
          hot: null,
          percussion: null,
          palpation: null,
          mobility: null,
          crack: null,
          restoration_defects: null,
          cbct: null,
        },
        {
          tooth_number: null,
          probing: null,
          cold: null,
          ept: null,
          hot: null,
          percussion: null,
          palpation: null,
          mobility: null,
          crack: null,
          restoration_defects: null,
          cbct: null,
        },
        {
          tooth_number: null,
          probing: null,
          cold: null,
          ept: null,
          hot: null,
          percussion: null,
          palpation: null,
          mobility: null,
          crack: null,
          restoration_defects: null,
          cbct: null,
        },
      ],

      radiograph: null,
      patient_record_id: null,
      diagnosis: {
        image: "someimage.png",
        title: "Test diagnosis",
        unexplained_at_this_time: true,
        tooth_id: 39,
        pulpals: [
          {
            id: 1,
          },
          {
            id: 4,
          },
        ],
        periapicals: [],
        non_odontogenics: [
          {
            id: 2,
          },
          {
            id: 3,
          },
          {
            id: 4,
          },
        ],
      },
      treatment: {
        informed_consent_signed: true,
        treatment_performed: "Performer",
        treatments_date_1st_visit: null,
        treatments_date_2nd_visit: null,
        anesthesia_mepi_3pct: false,
        lido_2pct_1_100: null,
        lido_2pct_1_50: null,
        arti_4pct: null,
        mar_0_5pct: null,
        cartridges: null,
        infiltration: false,
        block: true,
        r_dam: false,
        apex_locator: false,
        naoci_2_5pct_10ml: true,
        edta_17pct_10ml: false,
        caoh_medicament: false,
        ultrasonics: false,
        radiograph: false,
        niti_rotary_files: false,
        bc_sealer: false,
        kerr_sealer: false,
        rct: false,
        post_space: false,
        bc_liner_blue: false,
        bc_liner_white: false,
        temp_grip_cement: false,
        fuji_ix: false,
        irm: false,
        cotton: false,
        cavit: false,
        retx: false,
        solvent_chci3_5ml: false,
        removed_post: false,
        removed_crown: false,
        removed_obstruction: false,
        next_visit_date: null,
        next_visit_additional_notes: null,
        tx_doctor: null,
        report_filled_out_by: null,
        created_at: "2023-10-16T08:09:14.413Z",
        updated_at: "2023-10-16T08:09:14.413Z",
        tooth_id: 39,
        recommended_tx_options: [],
        recommended_following_tx_options: [],
        treatment_canals: [
          {
            id: 1,
          },
          {
            id: 4,
          },
          {
            id: 7,
          },
          {
            id: 9,
          },
        ],
        post_op_recommendations: [],
        treatment_rxes: [
          {
            id: 2,
          },
          {
            id: 4,
          },
          {
            id: 5,
          },
        ],
        treatment_recalls: [],
      },
      extra_oral_exams: [
        {
          id: 5,
        },
      ],
      radiographic_findings: [
        {
          id: 2,
        },
      ],
    },
  ],
};
