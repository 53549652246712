import "./style/checkbox.css";

export function Checkbox({ id, label, span, checked, handleChange }) {
  return (
    <div className={`span-${span} checkbox-endo`}>
      <label className="form-label checkbox-label">
        <input
          type="checkbox"
          className="checkbox-input"
          id={id}
          checked={checked ? checked : false}
          onChange={(e) => handleChange(e.target.checked)}
        ></input>
        <span className="checkbox-checkmark"></span>

        {label}
      </label>
    </div>
  );
}
