import logoutIcon from "../../images/logout_icon.png";
import "./style/profile_logout.css";
import { useAuth0 } from "@auth0/auth0-react";

export function ProfileLogout() {
  const { user, logout, isAuthenticated, isLoading, error } = useAuth0();

  return (
    <div className="profile-logout">
      <div className="profile-logout-divider"></div>
      <div>
        <b>
          <i>{isAuthenticated && user.name}</i>
        </b>
      </div>
      <div className="logout-element">
        {isAuthenticated && (
          <>
            <img src={logoutIcon} alt="log out icon" />
            <span onClick={(e) => logout()}>Log Out</span>
          </>
        )}
      </div>
    </div>
  );
}
