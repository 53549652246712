import "./style/other_treatment.css";
import { Checkbox } from "../../Shared/Checkbox";
import { TextField } from "../../Shared/TextField";
import { DateField } from "../../Shared/DateField";
import { ShortText } from "../../Shared/ShortText";
import { MultipleOptions } from "../../Shared/MultipleOptions";
import { RadioButtonOptions } from "../../Shared/RadioButtonOptions";
import { ActionButton } from "../../Shared/ActionButton";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function OtherTreatment({
  selectedTooth,
  patientRecord,
  handlePatientRecordChange,
  savePatientRecord,
  setCurrentTab,
}) {
  const [postOpRecommendations, setPostOpRecommendations] = useState([]);
  const [rxOptions, setRxOptions] = useState([]);
  const [recallOptions, setRecallOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(function () {
    async function getPostOpRecommendations() {
      const res = await fetch(`${rootURL}/post_op_recommendations`);
      const data = await res.json();
      setPostOpRecommendations(data);
    }
    getPostOpRecommendations();
  }, []);

  useEffect(function () {
    async function getRxOptions() {
      const res = await fetch(`${rootURL}/treatment_rxes`);
      const data = await res.json();
      setRxOptions(data);
    }
    getRxOptions();
  }, []);

  useEffect(function () {
    async function getRecallOptions() {
      const res = await fetch(`${rootURL}/treatment_recalls`);
      const data = await res.json();
      setRecallOptions(data);
    }
    getRecallOptions();
  }, []);

  function handleNewTreatmentData(newTreatmentData) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      treatment: newTreatmentData,
    };
    const newTeethData = patientRecord.tooths.map((el) =>
      el.number === newToothData.number ? newToothData : el
    );
    const newPatientRecord = {
      ...patientRecord,
      tooths: newTeethData,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleInformedConsentChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      informed_consent_signed: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleTreatmentPerformedChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      treatment_performed: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handle1stVisitChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      treatments_date_1st_visit: value.$d.toUTCString(),
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handle2ndVisitChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      treatments_date_2nd_visit: value.$d.toUTCString(),
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleAnesthesiaChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      anesthesia: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleNaoclChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      naoci_2_5pct_10ml: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleInfiltrationChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      infiltration: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleBlockChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      block: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRDamChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      r_dam: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleApexLocatorChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      apex_locator: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleEdtaChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      edta_17pct_10ml: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleCaohMedicamentChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      caoh_medicament: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleUltrasonicsChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      ultrasonics: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRadiographChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      radiograph: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRotaryChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      niti_rotary_files: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleBcSealerChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      bc_sealer: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleKerrSealerChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      kerr_sealer: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRctChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      rct: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handlePostSpaceChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      post_space: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleBcLinerBlueChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      bc_liner_blue: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleBcLinerWhiteChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      bc_liner_white: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleCementChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      temp_grip_cement: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleFujiChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      fuji_ix: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleIrmChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      irm: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleCottonChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      cotton: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleCavitChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      cavit: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRetxChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      retx: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleSolventChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      solvent_chci3_5ml: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRemPostChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      removed_post: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRemCrownChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      removed_crown: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleRemObstructionChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      removed_obstruction: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleMepiChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      mepi_3pct: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleLido100Changed(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      lido_2pct_1_100: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleLido50Changed(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      lido_2pct_1_50: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleArtiChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      arti_4pct: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleMarChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      mar_0_5pct: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleCartridgesChanged(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      cartridges: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handlePostOpChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      post_op_recommendations: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handleNewTreatmentData(newTreatmentData);
  }
  function handleNextVisitChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      next_visit_date: value.$d.toUTCString(),
    };
    handleNewTreatmentData(newTreatmentData);
  }

  function handleTreatmentRXChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      treatment_rxes: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handleNewTreatmentData(newTreatmentData);
  }

  function handleRecallChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      treatment_recalls: [{ id: value }],
    };
    handleNewTreatmentData(newTreatmentData);
  }

  function handleNextVisitNotesChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      next_visit_additional_notes: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }

  function handleTxDoctorChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      tx_doctor: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }

  function handleReportFilledChange(value) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newTreatmentData = {
      ...patientRecord.tooths[selectedTooth].treatment,
      report_filled_out_by: value,
    };
    handleNewTreatmentData(newTreatmentData);
  }

  return (
    <>
      <div className="other-treatment">
        <div className="other-treatment-consent-wrapper">
          <Checkbox
            id="other-treatment-consent"
            span="1"
            label="Informed consent reviewed and signed"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.informed_consent_signed
            }
            handleChange={handleInformedConsentChanged}
          />
        </div>
        <div className="other-treatment-text-row">
          <TextField
            id="treatment_performed"
            label="Treatment Performed"
            placeholder="Enter what was done"
            span="1"
            value={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatment_performed ?? ""
            }
            handleTextChange={handleTreatmentPerformedChange}
          />
          <DateField
            id="treatment_date_1"
            label="Treatment dates"
            span="1"
            placeholder="1st visit"
            value={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatments_date_1st_visit == null
                ? null
                : dayjs(
                    patientRecord.tooths[selectedTooth]?.treatment
                      ?.treatments_date_1st_visit
                  )
            }
            handleChange={handle1stVisitChange}
            handleOk={function () {}}
          />
          <DateField
            id="treatment_date_2"
            label="&nbsp;"
            span="1"
            placeholder="2nd visit"
            value={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatments_date_2nd_visit == null
                ? null
                : dayjs(
                    patientRecord.tooths[selectedTooth]?.treatment
                      ?.treatments_date_2nd_visit
                  )
            }
            handleChange={handle2ndVisitChange}
            handleOk={function () {}}
          />
        </div>
        <div className="other-treatment-checkboxes">
          <Checkbox
            id="anesthesia"
            span="1"
            label="Anesthesia:"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.anesthesia}
            handleChange={handleAnesthesiaChanged}
          />
          <ShortText
            id="mepi_3_percent"
            span="1"
            label="Mepi 3%"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.mepi_3pct ?? ""
            }
            handleTextChange={handleMepiChanged}
          />
          <ShortText
            id="lido_2_percent_1_100"
            span="1"
            label="Lido 2% 1:100"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.lido_2pct_1_100 ??
              ""
            }
            handleTextChange={handleLido100Changed}
          />
          <ShortText
            id="lido_2_percent_1_50"
            span="1"
            label="Lido 2% 1:50"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.lido_2pct_1_50 ??
              ""
            }
            handleTextChange={handleLido50Changed}
          />
          <ShortText
            id="arti_4_percent"
            span="1"
            label="Arti 4%:"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.arti_4pct ?? ""
            }
            handleTextChange={handleArtiChanged}
          />
          <ShortText
            id="mar_half_percent"
            span="1"
            label="Mar 0.5%"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.mar_0_5pct ?? ""
            }
            handleTextChange={handleMarChanged}
          />
          <ShortText
            id="cartridges"
            span="1"
            label="Cartridges"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.cartridges ?? ""
            }
            handleTextChange={handleCartridgesChanged}
          />
          <Checkbox
            id="infiltration"
            span="1"
            label="Infiltration"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.infiltration
            }
            handleChange={handleInfiltrationChanged}
          />
          <Checkbox
            id="block"
            span="1"
            label="Block"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.block}
            handleChange={handleBlockChanged}
          />
          <Checkbox
            id="r_dam"
            span="1"
            label="R Dam"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.r_dam}
            handleChange={handleRDamChanged}
          />
          <Checkbox
            id="apex_locator"
            span="1"
            label="Apex locator"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.apex_locator
            }
            handleChange={handleApexLocatorChanged}
          />
          <Checkbox
            id="naocl2point5percent"
            span="1"
            label="NaOCL 2.5% (10ml)"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.naoci_2_5pct_10ml
            }
            handleChange={handleNaoclChanged}
          />
          <Checkbox
            id="edta17percent"
            span="1"
            label="EDTA 17% (10ml)"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.edta_17pct_10ml
            }
            handleChange={handleEdtaChanged}
          />
          <Checkbox
            id="caoh_medicament"
            span="1"
            label="CaOH medicament"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.caoh_medicament
            }
            handleChange={handleCaohMedicamentChanged}
          />
          <Checkbox
            id="ultrasonics"
            span="1"
            label="Ultrasonics"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.ultrasonics
            }
            handleChange={handleUltrasonicsChanged}
          />
          <Checkbox
            id="radiograph"
            span="1"
            label="Radiograph"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.radiograph}
            handleChange={handleRadiographChanged}
          />
          <Checkbox
            id="niti_rotary_files"
            span="1"
            label="NiTi rotary files"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.niti_rotary_files
            }
            handleChange={handleRotaryChanged}
          />
          <Checkbox
            id="bc_sealer"
            span="1"
            label="BC sealer"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.bc_sealer}
            handleChange={handleBcSealerChanged}
          />
          <Checkbox
            id="kerr_sealer"
            span="1"
            label="Kerr sealer"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.kerr_sealer
            }
            handleChange={handleKerrSealerChanged}
          />
          <Checkbox
            id="rct"
            span="1"
            label="RCT"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.rct}
            handleChange={handleRctChanged}
          />
          <Checkbox
            id="post_space"
            span="1"
            label="Post space"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.post_space}
            handleChange={handlePostSpaceChanged}
          />
          <Checkbox
            id="bc_liner_blue"
            span="1"
            label="BC liner (blue)"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.bc_liner_blue
            }
            handleChange={handleBcLinerBlueChanged}
          />
          <Checkbox
            id="bc_liner_white"
            span="1"
            label="BC liner (white)"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.bc_liner_white
            }
            handleChange={handleBcLinerWhiteChanged}
          />
          <Checkbox
            id="temp_grip_cement"
            span="1"
            label="Temp grip cement"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.temp_grip_cement
            }
            handleChange={handleCementChanged}
          />
          <Checkbox
            id="fuji_ix"
            span="1"
            label="Fuji IX"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.fuji_ix}
            handleChange={handleFujiChanged}
          />
          <Checkbox
            id="irm"
            span="1"
            label="IRM"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.irm}
            handleChange={handleIrmChanged}
          />
          <Checkbox
            id="cotton"
            span="1"
            label="Cotton"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.cotton}
            handleChange={handleCottonChanged}
          />
          <Checkbox
            id="cavit"
            span="1"
            label="Cavit"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.cavit}
            handleChange={handleCavitChanged}
          />
          <Checkbox
            id="retx"
            span="1"
            label="RETX"
            checked={patientRecord.tooths[selectedTooth]?.treatment?.retx}
            handleChange={handleRetxChanged}
          />
          <Checkbox
            id="solvent_chcl2_5ml"
            span="1"
            label="Solvent (CHCl3, 5ml)"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.solvent_chci3_5ml
            }
            handleChange={handleSolventChanged}
          />
          <Checkbox
            id="removed_post"
            span="1"
            label="Removed post"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.removed_post
            }
            handleChange={handleRemPostChanged}
          />
          <Checkbox
            id="removed_crown"
            span="1"
            label="Removed crown"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment?.removed_crown
            }
            handleChange={handleRemCrownChanged}
          />
          <Checkbox
            id="removed_obstruction"
            span="1"
            label="Removed obstruction"
            checked={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.removed_obstruction
            }
            handleChange={handleRemObstructionChanged}
          />
        </div>
        <div className="options-row">
          <MultipleOptions
            id="post_op_recommendations"
            label="Post op recommendations"
            span="1"
            options={postOpRecommendations}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.post_op_recommendations == null
                ? []
                : patientRecord.tooths[
                    selectedTooth
                  ]?.treatment?.post_op_recommendations.map((el) => el.id)
            }
            handleChange={handlePostOpChange}
          />
        </div>
        <div className="options-row">
          <MultipleOptions
            id="rx"
            label="RX"
            span="1"
            options={rxOptions}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.treatment?.treatment_rxes ==
              null
                ? []
                : patientRecord.tooths[
                    selectedTooth
                  ]?.treatment?.treatment_rxes.map((el) => el.id)
            }
            handleChange={handleTreatmentRXChange}
          />
        </div>
        <div className="options-row">
          <RadioButtonOptions
            id="recall"
            span="1"
            options={recallOptions}
            selectedId={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatment_recalls?.[0]?.id
            }
            handleChange={handleRecallChange}
          >
            Recall
          </RadioButtonOptions>
        </div>
        <div className="other-treatment-text-row">
          <DateField
            id="next_visit_date"
            label="Next visit"
            span="1"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.next_visit_date ==
              null
                ? null
                : dayjs(
                    patientRecord.tooths[selectedTooth]?.treatment
                      ?.next_visit_date
                  )
            }
            handleChange={handleNextVisitChange}
            handleOk={function () {}}
          />
          <TextField
            id="additional_notes"
            label="Additional notes"
            placeholder="Additional notes"
            span="1"
            value={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.next_visit_additional_notes ?? ""
            }
            handleTextChange={handleNextVisitNotesChange}
          />
        </div>
        <div className="other-treatment-text-row">
          <TextField
            id="tx_doctor"
            label="Tx doctor"
            placeholder="Enter doctor's name"
            span="1"
            value={
              patientRecord.tooths[selectedTooth]?.treatment?.tx_doctor ?? ""
            }
            handleTextChange={handleTxDoctorChange}
          />
          <TextField
            id="report_filled_out_by"
            label="Report filled out by"
            placeholder="Enter name and date"
            span="1"
            value={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.report_filled_out_by ?? ""
            }
            handleTextChange={handleReportFilledChange}
          />
        </div>
      </div>
      <div className="diagnosis-action-button-container">
        <ActionButton
          id="save_entered_information"
          label="SAVE ENTERED INFORMATION"
          span="1"
          handleClick={function () {
            savePatientRecord();
            setCurrentTab(1);
          }}
        />
      </div>
    </>
  );
}
