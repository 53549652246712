import { useEffect, useState } from "react";
import "./style/assistant_form.css";
import { AssistantFormHeader } from "./AssistantFormHeader";
import { AssistantFormContentLayout } from "./AssistantFormContentLayout";
import { AssistantFormInnerLayout } from "./AssistantFormInnerLayout";
import { Exam } from "./Exam/Exam";
import { PatientFormSummary } from "./Exam/PatientFormSummary";
import { ToothSelector } from "./Exam/ToothSelector";
import { ClinicalTest } from "./Exam/ClinicalTest/ClinicalTest";
import { Diagnosis } from "./Diagnosis/Diagnosis";
import { Treatment } from "./Treatment/Treatment";
import { RecommendedAndCanal } from "./Treatment/ReccommendedAndCanal";
import { OtherTreatment } from "./Treatment/OtherTreatment";
import OpenAI from "openai";
import { useAuth0 } from "@auth0/auth0-react";
import { dummyPatientRecord } from "./DummyPatientRecord";
import { useParams } from "react-router-dom";
import { ActionButton } from "../Shared/ActionButton";
import { flushSync } from "react-dom";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function AssistantForm({
  toothSensitivityOptions,
  workOptions,
  anxiousOptions,
  medicationsOptions,
  allergicOptions,
}) {
  const [teethState, setTeethState] = useState([
    { number: 1, selected: false, active: false },
    { number: 2, selected: false, active: false },
    { number: 3, selected: false, active: false },
    { number: 4, selected: false, active: false },
    { number: 5, selected: false, active: false },
    { number: 6, selected: false, active: false },
    { number: 7, selected: false, active: false },
    { number: 8, selected: false, active: false },
    { number: 9, selected: false, active: false },
    { number: 10, selected: false, active: false },
    { number: 11, selected: false, active: false },
    { number: 12, selected: false, active: false },
    { number: 13, selected: false, active: false },
    { number: 14, selected: false, active: false },
    { number: 15, selected: false, active: false },
    { number: 16, selected: false, active: false },
    { number: 17, selected: false, active: false },
    { number: 18, selected: false, active: false },
    { number: 19, selected: false, active: false },
    { number: 20, selected: false, active: false },
    { number: 21, selected: false, active: false },
    { number: 22, selected: false, active: false },
    { number: 23, selected: false, active: false },
    { number: 24, selected: false, active: false },
    { number: 25, selected: false, active: false },
    { number: 26, selected: false, active: false },
    { number: 27, selected: false, active: false },
    { number: 28, selected: false, active: false },
    { number: 29, selected: false, active: false },
    { number: 30, selected: false, active: false },
    { number: 31, selected: false, active: false },
    { number: 32, selected: false, active: false },
  ]);
  const [currentTab, setCurrentTab] = useState(1);

  //900dc740-f611-4cfa-b89d-70e198680ea6
  const [patientRecord, setPatientRecord] = useState(dummyPatientRecord);
  const [formSummary, setFormSummary] = useState("");
  const [selectedTooth, setSelectedTooth] = useState(null);
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const openai = new OpenAI({
    apiKey: `${process.env.REACT_APP_CGPT_KEY}`, // defaults to process.env["OPENAI_API_KEY"]
    dangerouslyAllowBrowser: true,
  });

  let params = useParams();

  function scrollToTop() {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: "smooth" });
    }, 10);
  }

  useEffect(
    function () {
      async function getPatientData() {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              // audience: `EndoAPIURL`,
              // scope: "openid profile email offline_access read:current_user",
            },
          });

          const headers = { Authorization: `Bearer ${accessToken}` };
          const res = await fetch(
            `${rootURL}/patient_records/${params.patient_id}`,
            {
              headers,
            }
          );
          const data = await res.json();
          setPatientRecord(data);
          console.log("PATIENT DATA: " + JSON.stringify(data));
          setFirstToothSelectedAndTeethStateIfAnyTeethInPatient(data);
        } catch (e) {
          console.log(e.message);
        }
      }
      getPatientData();
    },
    [params.patient_id]
  );

  function setFirstToothSelectedAndTeethStateIfAnyTeethInPatient(data) {
    if (data.tooths?.[0] != null) {
      setSelectedTooth(0);
    }
    let first = true;
    let newTeethState = Object.assign([], teethState);
    data.tooths?.forEach((tooth) => {
      newTeethState = newTeethState.map((el, index) => {
        const toothState =
          index === tooth.number - 1
            ? { number: el.number, selected: true, active: first }
            : el;
        return toothState;
      });
      first = false;
    });
    setTeethState(newTeethState);
  }

  useEffect(
    function () {
      async function cgpt() {
        if (
          toothSensitivityOptions.length === 0 ||
          workOptions.length === 0 ||
          anxiousOptions.length === 0 ||
          medicationsOptions.length === 0 ||
          allergicOptions.length === 0 ||
          patientRecord.patient_name === ""
        ) {
          return;
        }
        if (patientRecord.chief_complaint == null) {
          setFormSummary(
            "Currently there is no chief complaint set, patient probably hasn't finished the form yet."
          );
          return;
        }
        const prompt = buildPrompt();
        setIsLoadingSummary(true);
        const chatCompletion = await openai.chat.completions.create({
          messages: [
            {
              role: "user",
              content: prompt,
            },
          ],
          model: "gpt-3.5-turbo",
          n: 1,
        });

        setFormSummary(chatCompletion.choices[0].message.content);
        setIsLoadingSummary(false);
      }

      cgpt();
    },
    [
      toothSensitivityOptions,
      workOptions,
      anxiousOptions,
      medicationsOptions,
      allergicOptions,
      patientRecord.chief_complaint,
      patientRecord.patient_name,
    ]
  );

  function goToNext() {
    setCurrentTab(2);
  }

  function buildPrompt() {
    const chiefComplaint = patientRecord.chief_complaint;
    const pain = patientRecord.pain_at_the_moment;
    const painTwoWeeks = patientRecord.pain_past_two_weeks;
    const localized = patientRecord.pain_localized_to_specific_tooth;
    const toothSensitivities = patientRecord.tooth_sensitivities
      .map((el) => getValueForId(el.id, toothSensitivityOptions))
      .join(", ");
    const workDone = patientRecord.work_done_in_the_pasts
      .map((el) => getValueForId(el.id, workOptions))
      .join(", ");
    const anxious = patientRecord.most_anxious_abouts
      .map((el) => getValueForId(el.id, anxiousOptions))
      .join(", ");
    const medications = patientRecord.medications
      .map((el) => getValueForId(el.id, medicationsOptions))
      .join(", ");
    const allergens = patientRecord.allergens
      .map((el) => getValueForId(el.id, allergicOptions))
      .join(", ");
    const promptElements = [
      chiefComplaint != null
        ? `Patient is coming with chief complaint of: ${chiefComplaint}`
        : null,
      painTwoWeeks != null
        ? `pain intensity was ${painTwoWeeks} in the last two weeks`
        : null,
      pain != null ? `pain intensity is ${pain} at the moment` : null,
      localized != null
        ? localized
          ? "pain is localized to specific tooth"
          : "pain is not localized to specific tooth"
        : null,
      toothSensitivities !== ""
        ? `tooth has following sensitivities (${toothSensitivities})`
        : null,
      workDone !== ""
        ? `tooth has following work done in the past (${workDone})`
        : null,
      anxious !== "" ? `patient is anxious about (${anxious})` : null,
      medications !== ""
        ? `patient is taking following medications (${medications})`
        : null,
      allergens !== "" ? `patient is allergic to (${allergens})` : null,
    ].filter((el) => el != null);
    const prompt =
      promptElements.join(", ") +
      ". Could you say all that in nicer way suitable to present to a dentist? All this is actually under category of 'chief complaint'";
    return prompt;
  }

  function getValueForId(id, options) {
    return options.filter((el) => el.id === id)[0].name;
  }

  async function savePatientRecord() {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {},
      });

      console.log(JSON.stringify(patientRecord));

      const res = await fetch(
        `${rootURL}/patient_records/${patientRecord.id}`,
        {
          // Enter your IP address here
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          mode: "cors",
          body: JSON.stringify(patientRecord), // body data type must match "Content-Type" header
        }
      );
      const data = await res.json();
    } catch (e) {
      console.log(e.message);
    }
  }

  async function generateFakeDiagnosis() {
    const res = await fetch(`${rootURL}/pulpals`);
    const data = await res.json();

    const res2 = await fetch(`${rootURL}/periapicals`);
    const data2 = await res2.json();
    const randomPulpal = data[Math.floor(Math.random() * data.length)];
    const randomPeriapical = data2[Math.floor(Math.random() * data2.length)];
    console.log(
      "RANDOM PULPAL: " +
        JSON.stringify(randomPulpal) +
        " RANDOM PERIAPICAL: " +
        JSON.stringify(randomPeriapical)
    );
    const newTooths = patientRecord.tooths?.map((el) => {
      if (
        !(
          el.diagnosis != null &&
          ((el.diagnosis.pulpals != null &&
            el.diagnosis.pulpals.length > 0 &&
            el.diagnosis.periapicals != null &&
            el.diagnosis.periapicals.length > 0) ||
            (el.diagnosis.non_odontogenics != null &&
              el.diagnosis.non_odontogenics.length > 0) ||
            el.diagnosis.unexplained_at_this_time === true)
        )
      ) {
        const newDiagnosis = {
          pulpals: [{ id: randomPulpal.id }],
          periapicals: [{ id: randomPeriapical.id }],
        };
        const newTooth = { ...el, diagnosis: newDiagnosis };
        return newTooth;
      } else {
        return el;
      }
    });
    const newPatientRecord = { ...patientRecord, tooths: newTooths };
    setPatientRecord(newPatientRecord);
    //save patient
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {},
      });

      const res = await fetch(
        `${rootURL}/patient_records/${newPatientRecord.id}`,
        {
          // Enter your IP address here
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          mode: "cors",
          body: JSON.stringify(newPatientRecord), // body data type must match "Content-Type" header
        }
      );
      const data = await res.json();
      goToNext();
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div>
      <AssistantFormHeader
        currentTab={currentTab}
        handleClick={setCurrentTab}
        patient_name={patientRecord.patient_name}
      />
      <AssistantFormContentLayout>
        <AssistantFormInnerLayout>
          {currentTab === 1 && (
            <Exam scrolltoTop={scrollToTop}>
              <PatientFormSummary
                formSummary={formSummary}
                patientRecord={patientRecord}
                isLoadingSummary={isLoadingSummary}
              />
              <ToothSelector
                selectedTooth={selectedTooth}
                patientRecord={patientRecord}
                handlePatientRecordChange={setPatientRecord}
                setSelectedTooth={setSelectedTooth}
                teethState={teethState}
                setTeethState={setTeethState}
              />
              <ClinicalTest
                selectedTooth={selectedTooth}
                patientRecord={patientRecord}
                handlePatientRecordChange={setPatientRecord}
              />
              <ActionButton
                id="generate_diagnosis"
                label="GENERATE DIAGNOSIS"
                handleClick={function () {
                  generateFakeDiagnosis();
                }}
              />
            </Exam>
          )}
          {currentTab === 2 && (
            <Diagnosis
              selectedTooth={selectedTooth}
              patientRecord={patientRecord}
              handlePatientRecordChange={setPatientRecord}
              setSelectedTooth={setSelectedTooth}
              teethState={teethState}
              setTeethState={setTeethState}
              savePatientRecord={savePatientRecord}
            />
          )}
          {currentTab === 3 && (
            <Treatment>
              <RecommendedAndCanal
                selectedTooth={selectedTooth}
                patientRecord={patientRecord}
                handlePatientRecordChange={setPatientRecord}
              />
              <OtherTreatment
                selectedTooth={selectedTooth}
                patientRecord={patientRecord}
                handlePatientRecordChange={setPatientRecord}
                savePatientRecord={savePatientRecord}
                setCurrentTab={setCurrentTab}
              />
            </Treatment>
          )}
        </AssistantFormInnerLayout>
      </AssistantFormContentLayout>
    </div>
  );
}
