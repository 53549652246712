import { useEffect, useState } from "react";
import { RadioButtonOptions } from "../../../Shared/RadioButtonOptions";
import "./style/clinical_test_matrix.css";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function ClinicalTestMatrix({
  patientRecord,
  handlePatientRecordChange,
  selectedTooth,
  tabSelected,
}) {
  const [probingOptions, setProbingOptions] = useState([]);
  const [hotColdOptions, setHotColdOptions] = useState([]);
  const [eptOptions, setEptOptions] = useState([]);
  const [percussionPalpationOptions, setPercussionPalpationOptions] = useState(
    []
  );
  const [mobilityOptions, setMobilityOptions] = useState([]);

  const [crackOptions, setCrackOptions] = useState([]);
  const [restorationDefectsOptions, setRestorationDefectsOptions] = useState(
    []
  );
  const [radiographOptions, setRadiographOptions] = useState([]);
  const [cbctOptions, setCbctOptions] = useState([]);

  function handleClinicalTestingDataChange(newClinicalTestingData) {
    const newClinicalTesting = patientRecord.tooths[
      selectedTooth
    ].clinical_testings.map((ct, index) =>
      index === tabSelected - 1 ? newClinicalTestingData : ct
    );
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      clinical_testings: newClinicalTesting,
    };
    const newTooths = patientRecord.tooths.map((t) =>
      t.number === newToothData.number ? newToothData : t
    );
    const newPatientRecord = {
      ...patientRecord,
      tooths: newTooths,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  useEffect(function () {
    async function getClinicalTestingOptions() {
      const res = await fetch(`${rootURL}/clinical_testings`);
      const data = await res.json();
      setProbingOptions(data.PROBING);
      setHotColdOptions(data.COLD);
      setEptOptions(data.EPT);
      setPercussionPalpationOptions(data.PERCUSSION);
      setMobilityOptions(data.MOBILITY);
      setCrackOptions(data.CRACK);
      setRestorationDefectsOptions(data["RESTORATION DEFECTS"]);
      setRadiographOptions(data.RADIOGRAPH);
      setCbctOptions(data.CBCT);
    }
    getClinicalTestingOptions();
  }, []);

  function handleProbingChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      probing: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleColdChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      cold: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleEptChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      ept: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleHotChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      hot: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handlePercussionChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      percussion: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handlePalpationChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      palpation: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleMobilityChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      mobility: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleCrackChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      crack: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleRestorationDefectsChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      restoration_defects: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleRadiographChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      radiograph: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  function handleCbctChange(test) {
    if (patientRecord.tooths[selectedTooth]?.clinical_testings == null) {
      return;
    }
    const newClinicalTestingData = {
      ...patientRecord.tooths[selectedTooth].clinical_testings?.[
        tabSelected - 1
      ],
      cbct: test,
    };
    handleClinicalTestingDataChange(newClinicalTestingData);
  }

  return (
    <div className="clinical-test-matrix">
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={probingOptions}
          span="1"
          name="clinical-test-probing"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.probing
          }
          handleChange={handleProbingChange}
        >
          PROBING
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={hotColdOptions}
          span="1"
          name="clinical-test-cold"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.cold
          }
          handleChange={handleColdChange}
        >
          COLD
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={eptOptions}
          span="1"
          name="clinical-test-ept"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.ept
          }
          handleChange={handleEptChange}
        >
          EPT
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={hotColdOptions}
          span="1"
          name="clinical-test-hot"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.hot
          }
          handleChange={handleHotChange}
        >
          HOT
        </RadioButtonOptions>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={percussionPalpationOptions}
          span="1"
          name="clinical-test-percussion"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.percussion
          }
          handleChange={handlePercussionChange}
        >
          PERCUSSION
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={percussionPalpationOptions}
          span="1"
          name="clinical-test-palpation"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.palpation
          }
          handleChange={handlePalpationChange}
        >
          PALPATION
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={mobilityOptions}
          span="1"
          name="clinical-test-mobility"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.mobility
          }
          handleChange={handleMobilityChange}
        >
          MOBILITY
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={crackOptions}
          span="1"
          name="clinical-test-crack"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.crack
          }
          handleChange={handleCrackChange}
        >
          CRACK
        </RadioButtonOptions>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={restorationDefectsOptions}
          span="1"
          name="clinical-test-restoration-defects"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.restoration_defects
          }
          handleChange={handleRestorationDefectsChange}
        >
          RESTORATION DEFECTS
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={radiographOptions}
          span="1"
          name="clinical-test-radiograph"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.radiograph
          }
          handleChange={handleRadiographChange}
        >
          RADIOGRAPH
        </RadioButtonOptions>
        <div className="clinical-test-cell-divider"></div>
      </div>
      <div className="clinical-test-cell">
        <RadioButtonOptions
          options={cbctOptions}
          span="1"
          name="clinical-test-cbct"
          selectedId={
            patientRecord.tooths[selectedTooth]?.clinical_testings?.[
              tabSelected - 1
            ]?.cbct
          }
          handleChange={handleCbctChange}
        >
          CBCT
        </RadioButtonOptions>
      </div>
    </div>
  );
}
