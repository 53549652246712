import { useEffect, useState } from "react";
import { MultipleOptions } from "../../Shared/MultipleOptions";
import { TextField } from "../../Shared/TextField";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function CanalTable({
  selectedTooth,
  patientRecord,
  handlePatientRecordChange,
}) {
  const [firstRootOptions, setFirstRootOptions] = useState([]);
  const [secondRootOptions, setSecondRootOptions] = useState([]);
  const [thirdRootOptions, setThirdRootOptions] = useState([]);
  const [fourthRootOptions, setFourthRootOptions] = useState([]);
  const [firstRootMm, setFirstRootMm] = useState("");
  const [secondRootMm, setSecondRootMm] = useState("");
  const [thirdRootMm, setThirdRootMm] = useState("");
  const [fourthRootMm, setFourthRootMm] = useState("");
  const [firstRootMAF, setFirstRootMAF] = useState("");
  const [secondRootMAF, setSecondRootMAF] = useState("");
  const [thirdRootMAF, setThirdRootMAF] = useState("");
  const [fourthRootMAF, setFourthRootMAF] = useState("");

  useEffect(function () {
    async function getTreatmentCanalOptions() {
      const res = await fetch(`${rootURL}/treatment_canals`);
      const data = await res.json();
      setFirstRootOptions(data.filter((el, index) => index < 3));
      setSecondRootOptions(data.filter((el, index) => index > 2 && index < 6));
      setThirdRootOptions(data.filter((el, index) => index > 5 && index < 8));
      setFourthRootOptions(data.filter((el, index) => index > 7 && index < 10));
    }
    getTreatmentCanalOptions();
  }, []);

  function handleTreatmentChange(newTreatmentCanals) {
    const newTreatment = {
      ...patientRecord.tooths[selectedTooth].treatment,
      treatment_canals: newTreatmentCanals,
    };
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      treatment: newTreatment,
    };
    const newTooths = patientRecord.tooths.map((t) =>
      t.number === newToothData.number ? newToothData : t
    );
    const newPatientRecord = {
      ...patientRecord,
      tooths: newTooths,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleFirstRootChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const initialTreatmentCanals = [{}, {}, {}, {}];
    const newTreatmentCanals = (
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals == null ||
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals.length ===
        0
        ? initialTreatmentCanals
        : patientRecord.tooths[selectedTooth].treatment?.treatment_canals
    ).map((el, index) =>
      index === 0 ? (array[0] == null ? {} : { id: array[0] }) : el
    );
    handleTreatmentChange(newTreatmentCanals);
  }
  function handleSecondRootChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const initialTreatmentCanals = [{}, {}, {}, {}];
    const newTreatmentCanals = (
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals == null ||
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals.length ===
        0
        ? initialTreatmentCanals
        : patientRecord.tooths[selectedTooth].treatment?.treatment_canals
    ).map((el, index) =>
      index === 1 ? (array[0] == null ? {} : { id: array[0] }) : el
    );

    handleTreatmentChange(newTreatmentCanals);
  }
  function handleThirdRootChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const initialTreatmentCanals = [{}, {}, {}, {}];
    const newTreatmentCanals = (
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals == null ||
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals.length ===
        0
        ? initialTreatmentCanals
        : patientRecord.tooths[selectedTooth].treatment?.treatment_canals
    ).map((el, index) =>
      index === 2 ? (array[0] == null ? {} : { id: array[0] }) : el
    );
    handleTreatmentChange(newTreatmentCanals);
  }
  function handleFourthRootChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const initialTreatmentCanals = [{}, {}, {}, {}];
    const newTreatmentCanals = (
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals == null ||
      patientRecord.tooths[selectedTooth].treatment?.treatment_canals.length ===
        0
        ? initialTreatmentCanals
        : patientRecord.tooths[selectedTooth].treatment?.treatment_canals
    ).map((el, index) =>
      index === 3 ? (array[0] == null ? {} : { id: array[0] }) : el
    );
    handleTreatmentChange(newTreatmentCanals);
  }

  return (
    <div className="recommended-canal-tx-container">
      <CanalHeaderContent />
      <CanalRowContent
        multipleOptions={
          <MultipleOptions
            id="first_root_option"
            label=""
            span="1"
            options={firstRootOptions}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatment_canals?.[0]?.id == null
                ? []
                : [
                    patientRecord.tooths[selectedTooth].treatment
                      ?.treatment_canals?.[0].id,
                  ]
            }
            handleChange={handleFirstRootChange}
            isExclusive={true}
          />
        }
        textField1={
          <TextField
            id="first_root_mm"
            label=""
            placeholder=""
            span=""
            value={firstRootMm}
            handleTextChange={setFirstRootMm}
          />
        }
        textField2={
          <TextField
            id="first_root_MAF"
            label=""
            placeholder=""
            span=""
            value={firstRootMAF}
            handleTextChange={setFirstRootMAF}
          />
        }
      />
      <CanalRowContent
        multipleOptions={
          <MultipleOptions
            id="second_root_option"
            label=""
            span="1"
            options={secondRootOptions}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatment_canals?.[1]?.id == null
                ? []
                : [
                    patientRecord.tooths[selectedTooth].treatment
                      ?.treatment_canals?.[1].id,
                  ]
            }
            handleChange={handleSecondRootChange}
            isExclusive={true}
          />
        }
        textField1={
          <TextField
            id="second_root_mm"
            label=""
            placeholder=""
            span=""
            value={secondRootMm}
            handleTextChange={setSecondRootMm}
          />
        }
        textField2={
          <TextField
            id="second_root_MAF"
            label=""
            placeholder=""
            span=""
            value={secondRootMAF}
            handleTextChange={setSecondRootMAF}
          />
        }
      />
      <CanalRowContent
        multipleOptions={
          <MultipleOptions
            id="third_root_option"
            label=""
            span="1"
            options={thirdRootOptions}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatment_canals?.[2]?.id == null
                ? []
                : [
                    patientRecord.tooths[selectedTooth].treatment
                      ?.treatment_canals?.[2].id,
                  ]
            }
            handleChange={handleThirdRootChange}
            isExclusive={true}
          />
        }
        textField1={
          <TextField
            id="third_root_mm"
            label=""
            placeholder=""
            span=""
            value={thirdRootMm}
            handleTextChange={setThirdRootMm}
          />
        }
        textField2={
          <TextField
            id="third_root_MAF"
            label=""
            placeholder=""
            span=""
            value={thirdRootMAF}
            handleTextChange={setThirdRootMAF}
          />
        }
      />
      <CanalRowContent
        multipleOptions={
          <MultipleOptions
            id="fourth_root_option"
            label=""
            span="1"
            options={fourthRootOptions}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.treatment
                ?.treatment_canals?.[3]?.id == null
                ? []
                : [
                    patientRecord.tooths[selectedTooth].treatment
                      ?.treatment_canals?.[3].id,
                  ]
            }
            handleChange={handleFourthRootChange}
            isExclusive={true}
          />
        }
        textField1={
          <TextField
            id="fourth_root_mm"
            label=""
            placeholder=""
            span=""
            value={fourthRootMm}
            handleTextChange={setFourthRootMm}
          />
        }
        textField2={
          <TextField
            id="fourth_root_MAF"
            label=""
            placeholder=""
            span=""
            value={fourthRootMAF}
            handleTextChange={setFourthRootMAF}
          />
        }
      />
    </div>
  );
}

function CanalHeaderContent() {
  return (
    <>
      <div>
        <span>Canal</span>
      </div>
      <div>
        <span>mm</span>
      </div>
      <div>
        <span>MAF</span>
      </div>
    </>
  );
}

function CanalRowContent({ multipleOptions, textField1, textField2 }) {
  return (
    <>
      <div className="options-cell">{multipleOptions}</div>
      <div className="text-field-cell">{textField1}</div>
      <div className="text-field-cell">{textField2}</div>
    </>
  );
}
