import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import "./style/endo_range.css";
import { useState } from "react";

const SlidTool = createSliderWithTooltip(Slider);

export function EndoRange({
  id,
  label,
  min,
  max,
  span,
  minLabel,
  midLabel,
  maxLabel,
  value,
  handleChange,
  error = false,
}) {
  const [touched, setTouched] = useState(false);
  const marks = {
    0: (
      <div
        style={
          error
            ? {
                paddingLeft: 34,
                paddingTop: 10,
                fontStyle: "italic",
                color: "#ff4f6e",
              }
            : {
                paddingLeft: 34,
                paddingTop: 10,
                fontStyle: "italic",
                color: "#C9D3DD",
              }
        }
      >
        {minLabel}
      </div>
    ),
    5: (
      <div
        style={
          error
            ? { paddingTop: 10, fontStyle: "italic", color: "#ff4f6e" }
            : { paddingTop: 10, fontStyle: "italic", color: "#C9D3DD" }
        }
      >
        {midLabel}
      </div>
    ),
    10: (
      <div
        style={
          error
            ? {
                paddingRight: 60,
                paddingTop: 10,
                fontStyle: "italic",
                color: "#ff4f6e",
              }
            : {
                paddingRight: 60,
                paddingTop: 10,
                fontStyle: "italic",
                color: "#C9D3DD",
              }
        }
      >
        {maxLabel}
      </div>
    ),
  };
  return (
    <div className={`span-${span} endo-range`}>
      <label className="form-label">
        {label}

        <SlidTool
          id={id}
          min={min}
          max={max}
          marks={marks}
          tabIndex={1}
          value={value}
          className="endo-range-input"
          handleStyle={
            touched
              ? {
                  width: "30px",
                  height: "30px",
                  marginTop: "-14px",
                  borderColor: "#549BDC",
                  backgroundColor: "#549BDC",
                  boxShadow: "2px 2px 50px 2px #1569b733",
                }
              : error
              ? {
                  width: "30px",
                  height: "30px",
                  marginTop: "-14px",
                  borderColor: "#ff4f6e",
                  backgroundColor: "#ff4f6e",
                  boxShadow: "2px 2px 50px 2px #1569b733",
                }
              : {
                  width: "30px",
                  height: "30px",
                  marginTop: "-14px",
                  borderColor: "#B0D6F9",
                  backgroundColor: "#B0D6F9",
                  boxShadow: "2px 2px 50px 2px #1569b733",
                }
          }
          railStyle={
            touched
              ? { backgroundColor: "#549BDC", height: 1, marginTop: 1 }
              : error
              ? { backgroundColor: "#ff4f6e", height: 1, marginTop: 1 }
              : { backgroundColor: "#B0D6F9", height: 1, marginTop: 1 }
          }
          trackStyle={
            touched
              ? { backgroundColor: "#549BDC", height: 1, marginTop: 1 }
              : error
              ? { backgroundColor: "#ff4f6e", height: 1, marginTop: 1 }
              : { backgroundColor: "#B0D6F9", height: 1, marginTop: 1 }
          }
          onChange={(e) => {
            handleChange(e);
          }}
          onAfterChange={(e) => {
            document.activeElement.blur();
            setTouched(true);
          }}
        />
      </label>
    </div>
  );
}
