import { useEffect, useState } from "react";
import { MultipleOptions } from "../../Shared/MultipleOptions";
import { TextField } from "../../Shared/TextField";
import { CanalTable } from "./CanalTable";
import "./style/recommended_and_canal.css";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function RecommendedAndCanal({
  selectedTooth,
  patientRecord,
  handlePatientRecordChange,
}) {
  const [recommendedTXOptions, setRecommendedTXOptions] = useState([]);
  const [recommendedFollowingTxOptions, setRecommendedFollowingTxOptions] =
    useState([]);

  useEffect(function () {
    async function getRecommendedTXOptions() {
      const res = await fetch(`${rootURL}/recommended_tx_options`);
      const data = await res.json();
      setRecommendedTXOptions(data);
    }
    getRecommendedTXOptions();
  }, []);

  useEffect(function () {
    async function getRecommendedFollowingTxOptions() {
      const res = await fetch(`${rootURL}/recommended_following_tx_options`);
      const data = await res.json();
      setRecommendedFollowingTxOptions(data);
    }
    getRecommendedFollowingTxOptions();
  }, []);

  function handleTreatmentChange(newTreatment) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      treatment: newTreatment,
    };
    const newTeeth = patientRecord.tooths.map((el) =>
      el.number === newToothData.number ? newToothData : el
    );
    const newPatientRecord = { ...patientRecord, tooths: newTeeth };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleRecommendedTxChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newRecommendedTxOptions = array.map((el) => {
      let hash = {};
      hash.id = el;
      return hash;
    });
    const newTreatment = {
      ...patientRecord.tooths[selectedTooth].treatment,
      recommended_tx_options: newRecommendedTxOptions,
    };
    handleTreatmentChange(newTreatment);
  }

  function handleRecommendedFollowingTxChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newRecommendedFollowingTxOptions = array.map((el) => {
      let hash = {};
      hash.id = el;
      return hash;
    });
    const newTreatment = {
      ...patientRecord.tooths[selectedTooth].treatment,
      recommended_following_tx_options: newRecommendedFollowingTxOptions,
    };
    handleTreatmentChange(newTreatment);
  }

  return (
    <div className="recommended-and-canal">
      <div className="treatment-multiple-options-wrapper">
        <MultipleOptions
          id="recommended_tx"
          label="Recommended TX"
          span="1"
          options={recommendedTXOptions}
          selectedIds={
            patientRecord.tooths[selectedTooth]?.treatment
              ?.recommended_tx_options == null
              ? []
              : patientRecord.tooths[
                  selectedTooth
                ].treatment?.recommended_tx_options.map((el) => el.id)
          }
          handleChange={handleRecommendedTxChange}
        />
      </div>
      <CanalTable
        selectedTooth={selectedTooth}
        patientRecord={patientRecord}
        handlePatientRecordChange={handlePatientRecordChange}
      />
      <div className="treatment-multiple-options-wrapper">
        <MultipleOptions
          id="recommended_following_tx"
          label="Recommended following TX option(s)"
          span="1"
          options={recommendedFollowingTxOptions}
          selectedIds={
            patientRecord.tooths[selectedTooth]?.treatment
              ?.recommended_following_tx_options == null
              ? []
              : patientRecord.tooths[
                  selectedTooth
                ].treatment.recommended_following_tx_options.map((el) => el.id)
          }
          handleChange={handleRecommendedFollowingTxChange}
        />
      </div>
    </div>
  );
}
