import dentistIllustration from "../../images/dentist_illustration.png";
import "./patient_landing.css";
import { ActionButton } from "../Shared/ActionButton";
import { Link, useParams } from "react-router-dom";
import "../Shared/style/action_button.css";

export function PatientLanding() {
  let params = useParams();
  return (
    <div style={{ margin: "auto" }} className="container">
      <div className="patient-landing-headers">
        <h1>WELCOME</h1>
        <h2>to MicroSurgical Endo</h2>
      </div>
      <div className="patient-landing-illustration">
        <img
          src={dentistIllustration}
          alt="background gradient"
          className="qr-image"
          style={{ display: "block" }}
        />
      </div>
      <div className="patient-landing-instruction">
        <p>
          We look forward to helping you with you endodontic needs. <br />
          Please fill out the form prior to your appointment.
        </p>
      </div>

      <div className={`span-1 button-action-container`}>
        <Link to={`/patient/${params.patient_token}`}>
          <button id="fill_the_form" className="button-action">
            FILL OUT THE FORM
          </button>
        </Link>
      </div>

      {/* <ActionButton id="fill_the_form" label="FILL OUT THE FORM" /> */}
    </div>
  );
}
