import { useState } from "react";
import { YesNoButtons } from "./YesNoButtons";
import "./style/yes_no.css";

export default function YesNoText({
  id,
  label,
  placeholder,
  span,
  setting,
  settingText,
  handleChange,
  error = false,
  errorText = false,
}) {
  return (
    <div className={`span-${span} yes-no-text`}>
      <label
        dangerouslySetInnerHTML={{ __html: label }}
        className="form-label"
      ></label>
      <YesNoButtons
        id={id}
        setting={setting == null ? "none" : setting ? "yes" : "no"}
        setSetting={function handleChanges(setting) {
          handleChange(setting, settingText);
        }}
        error={error}
      />
      <textarea
        className={errorText ? "text-area-input error" : "text-area-input"}
        placeholder={placeholder}
        value={settingText}
        onChange={(e) =>
          handleChange(
            setting == null ? "none" : setting ? "yes" : "no",
            e.target.value
          )
        }
      ></textarea>
    </div>
  );
}
