import "./style/patient_form_summary.css";
import { TextArea } from "../../Shared/TextArea.js";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

export function PatientFormSummary({
  patientRecord,
  formSummary,
  isLoadingSummary = false,
}) {
  return (
    <div className="patient-form-summary">
      <div className="patient-form-summary-header">
        <span>
          <div className="patient-form-summary-title-with-loader">
            Patient's form summary
            <ThreeDots
              height="20"
              width="20"
              radius="4"
              color="#BBB"
              ariaLabel="three-dots-loading"
              wrapperStyle={{ marginLeft: "20px" }}
              wrapperClassName=""
              visible={isLoadingSummary}
            />
          </div>
        </span>

        <Link to={`/patient/${patientRecord.token}`}>
          <button>EDIT FORM</button>
        </Link>
      </div>
      <div className="patient-form-summary-text-area">
        <div className="patient-form-summary-text">
          <p>{formSummary}</p>
        </div>
        {/* <TextArea
          id="patient_form_summary_text"
          label={null}
          span="1"
          value={formSummary}
          placeholder="The patient hasn't filled the form yet"
        /> */}
      </div>
    </div>
  );
}
