export function AdminDashboardContentLayout({ children }) {
  return (
    <div className="admin-dashboard-content-layout">
      <div style={{ gridColumn: "span 3" }}></div>
      <div></div>
      <div>{children}</div>
      <div></div>
      <div style={{ gridColumn: "span 3" }}></div>
    </div>
  );
}
