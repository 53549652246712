import { ProfileLogout } from "../Shared/ProfileLogout";
import arrowLeft from "../../images/arrow-left.svg";
import { AssistantFormTabBar } from "./AssistantFormTabBar";
import { useNavigate } from "react-router-dom";

export function AssistantFormHeader({ currentTab, handleClick, patient_name }) {
  const navigate = useNavigate();
  return (
    <header className="assistant-form-header">
      <button
        className="assistant-form-back-button"
        onClick={(e) => navigate(`/admin_dashboard`)}
      >
        <img src={arrowLeft} alt="left arrow" />
        <span></span>
      </button>
      <div>
        <AssistantFormTabBar
          currentTab={currentTab}
          handleClick={handleClick}
        />
      </div>
      <div style={{ paddingLeft: "25px" }}>Patient: {patient_name}</div>
      {/* <div>
        <ProfileLogout />
      </div> */}
    </header>
  );
}
