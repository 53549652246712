import { useEffect, useState } from "react";
import { ActionButton } from "../Shared/ActionButton";
import { MultipleOptions } from "../Shared/MultipleOptions";
import { RadioButtonOptions } from "../Shared/RadioButtonOptions";
import { Subsection } from "../Shared/Subsection";
import { TextArea } from "../Shared/TextArea";
import { YesNo } from "../Shared/YesNo";
import "./style/patient_form.css";

export default function DentalHistory({
  workOptions,
  anxiousOptions,
  patientRecord,
  handlePatientRecordChange,
  handleActionButtonClicked,
  savePatientRecord,
}) {
  const [errorIds, setErrorIds] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function isEmpty(value) {
    return (
      value === null ||
      value === "" ||
      value === undefined ||
      value.length === 0
    );
  }

  function isNone(optionsIds, optionsSet) {
    if (optionsIds == null) return false;
    const idOfNoneInTheOptionsSet = optionsSet.filter(
      (el) => el.name === "None"
    )?.[0]?.id;

    if (optionsIds.some((el) => el.id === idOfNoneInTheOptionsSet)) {
      return true;
    } else {
      return false;
    }
  }

  function validateFields() {
    let newErrorIds = Object.assign({}, errorIds);
    isEmpty(patientRecord.clench_or_grind_teeth)
      ? (newErrorIds.clench_or_grind_teeth = true)
      : delete newErrorIds.clench_or_grind_teeth;
    isEmpty(patientRecord.tmj_tmd_jaw_joint_problems)
      ? (newErrorIds.tmj_tmd_jaw_joint_problems = true)
      : delete newErrorIds.tmj_tmd_jaw_joint_problems;
    isEmpty(patientRecord.work_done_in_the_pasts)
      ? (newErrorIds.work_done_in_the_pasts = true)
      : delete newErrorIds.work_done_in_the_pasts;
    isEmpty(patientRecord.time_of_last_work_dones_id) &&
    !isNone(patientRecord.work_done_in_the_pasts, workOptions)
      ? (newErrorIds.time_of_last_work_dones_id = true)
      : delete newErrorIds.time_of_last_work_dones_id;
    isEmpty(patientRecord.anxious_towards_dental_treatment)
      ? (newErrorIds.anxious_towards_dental_treatment = true)
      : delete newErrorIds.anxious_towards_dental_treatment;
    isEmpty(patientRecord.most_anxious_abouts) &&
    patientRecord?.anxious_towards_dental_treatment
      ? (newErrorIds.most_anxious_abouts = true)
      : delete newErrorIds.most_anxious_abouts;

    setErrorIds(newErrorIds);
    return newErrorIds;
  }

  const workDoneTimeOptions = [
    { id: 1, name: "Within last month" },
    { id: 2, name: "Within last year" },
    { id: 3, name: "More than one year ago" },
  ];

  function handleNextTabClicked() {
    const newErrorIds = validateFields();
    if (
      Object.keys(newErrorIds).length === 0 &&
      newErrorIds.constructor === Object
    ) {
      savePatientRecord(function () {
        handleActionButtonClicked(3);
      });
    } else {
      return;
    }
  }

  function handleClenchOrGrindTeethChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      clench_or_grind_teeth:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleTMJChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      tmj_tmd_jaw_joint_problems:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleWorkDoneInThePastChange(array) {
    const newPatientRecord = {
      ...patientRecord,
      work_done_in_the_pasts: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleWorkDoneTimeChange(workDoneTime) {
    const newPatientRecord = {
      ...patientRecord,
      time_of_last_work_dones_id: workDoneTime,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleAnxiousChange(setting) {
    const newPatientRecord = {
      ...patientRecord,
      anxious_towards_dental_treatment:
        setting === "none" ? null : setting === "yes" ? true : false,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleMostAnxiousChange(array) {
    const newPatientRecord = {
      ...patientRecord,
      most_anxious_abouts: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function handleLessAnxiousChange(text) {
    const newPatientRecord = {
      ...patientRecord,
      how_to_make_this_less_anxious: text,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  return (
    <div className="dental-history">
      <form className="dental-history-form">
        <Subsection>
          <div>ABOUT YOUR TEETH</div>
          <YesNo
            id="clench_or_grind"
            label="Do you clench or grind your teeth?"
            span="1"
            setting={patientRecord.clench_or_grind_teeth}
            handleChange={handleClenchOrGrindTeethChange}
            error={errorIds?.clench_or_grind_teeth ?? false}
          />
          <YesNo
            id="jaw_joint_problems"
            label="Do you have TMJ/TMD (jaw joint problems)?"
            span="1"
            setting={patientRecord.tmj_tmd_jaw_joint_problems}
            handleChange={handleTMJChange}
            error={errorIds?.tmj_tmd_jaw_joint_problems ?? false}
          />
          <MultipleOptions
            id="work_done_before"
            label="Have you had dental work done in the affected region?"
            span="1"
            options={workOptions}
            selectedIds={
              patientRecord.work_done_in_the_pasts == null
                ? []
                : patientRecord.work_done_in_the_pasts.map((el) => el.id)
            }
            handleChange={handleWorkDoneInThePastChange}
            error={errorIds?.work_done_in_the_pasts ?? false}
          />
          <RadioButtonOptions
            span="1"
            name="work_done_time"
            options={workDoneTimeOptions}
            selectedId={patientRecord.time_of_last_work_dones_id}
            handleChange={handleWorkDoneTimeChange}
            error={errorIds?.time_of_last_work_dones_id ?? false}
          >
            When have you had work done last?
          </RadioButtonOptions>
        </Subsection>
        <Subsection>
          <div>ABOUT YOUR EXPERIENCE</div>
          <YesNo
            id="anxious"
            label="Are you anxious towards dental treatment"
            span="1"
            setting={patientRecord.anxious_towards_dental_treatment}
            handleChange={handleAnxiousChange}
            error={errorIds?.anxious_towards_dental_treatment ?? false}
          />
          <MultipleOptions
            id="anxious_options"
            label="What are you most anxious about?"
            span="1"
            options={anxiousOptions}
            selectedIds={
              patientRecord.most_anxious_abouts == null
                ? []
                : patientRecord.most_anxious_abouts.map((el) => el.id)
            }
            handleChange={handleMostAnxiousChange}
            disabled={
              patientRecord.anxious_towards_dental_treatment == null
                ? true
                : patientRecord.anxious_towards_dental_treatment
                ? false
                : true
            }
            error={errorIds?.most_anxious_abouts ?? false}
          />
          <TextArea
            id="how_to_improve_experience"
            label="How can our office make this a less anxious 
            and more comfortable experience for you?"
            placeholder="Explain what would help you"
            value={patientRecord.how_to_make_this_less_anxious ?? ""}
            span="1"
            handleTextChange={handleLessAnxiousChange}
          />
        </Subsection>
        <ActionButton
          id="to_medical_history"
          label="SAVE AND CONTINUE TO MEDICAL HISTORY"
          span="2"
          handleClick={handleNextTabClicked}
        />
      </form>
    </div>
  );
}
