import "./style/assistant_form_content_layout.css";

export function AssistantFormContentLayout({ children }) {
  return (
    <div className="assistant-form-content-layout">
      <div style={{ gridColumn: "span 3" }}></div>
      <div></div>
      <div>{children}</div>
      <div></div>
      <div style={{ gridColumn: "span 3" }}></div>
    </div>
  );
}
