import "./style/tooth_selector.css";
import deleteIcon from "../../../images/delete-icon.png";
import { Subsection } from "../../Shared/Subsection";
import { MultipleOptions } from "../../Shared/MultipleOptions";
import { TextArea } from "../../Shared/TextArea";
import { Checkbox } from "../../Shared/Checkbox";
import { ToothTab } from "./ToothTab";

export function ToothSelector({
  patientRecord,
  handlePatientRecordChange,
  selectedTooth,
  setSelectedTooth,
  teethState,
  setTeethState,
}) {
  const extraOralExamOptions = [
    { id: 1, name: "Related Swelling" },
    { id: 2, name: "Unrelated sweling" },
    { id: 3, name: "Symmetry" },
    { id: 4, name: "Pain to palpation" },
    { id: 5, name: "WNL" },
    { id: 6, name: "Lymphadenopathy" },
    { id: 7, name: "Sinus tract" },
    { id: 8, name: "Lacerations/Ulcers/Signs of Trauma" },
  ];

  const radiographicFindingsOptions = [
    { id: 1, name: "Caries" },
    { id: 2, name: "Fracture" },
    { id: 3, name: "Resorption" },
    { id: 4, name: "PARL" },
    { id: 5, name: "PDL Widening" },
    { id: 6, name: "WNL" },
    { id: 7, name: "Inconclusive" },
    { id: 8, name: "Radiograph not made" },
  ];

  function handleToothDataChange(newToothData) {
    const newTooths = patientRecord.tooths.map((t) =>
      t.number === newToothData.number ? newToothData : t
    );
    const newPatientRecord = {
      ...patientRecord,
      tooths: newTooths,
    };
    handlePatientRecordChange(newPatientRecord);
  }

  function extraOralExamsChanged(array) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      extra_oral_exams: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handleToothDataChange(newToothData);
  }

  function handleAdditionalNotesChange(text) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      extra_oral_exam_notes: text,
    };
    handleToothDataChange(newToothData);
  }

  function handleOralCancerScreeningChange(value) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      oral_cancer_screening_done: value,
    };
    handleToothDataChange(newToothData);
  }

  function handleRadiographicFindingsChange(array) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      radiographic_findings: array.map((el) => {
        let hash = {};
        hash.id = el;
        return hash;
      }),
    };
    handleToothDataChange(newToothData);
  }

  function handleRadiographicFindingsNotesChange(text) {
    const newToothData = {
      ...patientRecord.tooths[selectedTooth],
      radiographic_findings_notes: text,
    };
    handleToothDataChange(newToothData);
  }

  return (
    <div className="tooth-selector">
      <div className="tooth-selector-header">
        <div>
          <div className="tooth-selector-header-title">
            TOOTH #{patientRecord.tooths[selectedTooth]?.number}
          </div>
          <div className="tooth-selector-header-current-tooth-text">
            {selectedTooth == null
              ? "No tooth is selected. To add tooth data, click on that tooth."
              : `You are viewing data for tooth #${patientRecord.tooths[selectedTooth]?.number}. To view or edit data for a different tooth, click on that tooth.`}
          </div>
        </div>
        <div className="tooth-selector-header-delete-icon">
          <ToothDeleteButton />
        </div>
      </div>
      <div className="teeth">
        <ToothTab
          setSelectedTooth={setSelectedTooth}
          patientRecord={patientRecord}
          handlePatientRecordChange={handlePatientRecordChange}
          teethState={teethState}
          setTeethState={setTeethState}
        />
      </div>
      <div className="exam-and-radiographic-container">
        <Subsection>
          <div>EXTRA ORAL EXAM</div>
          <MultipleOptions
            id="extra_oral_exam_options"
            label={null}
            span="1"
            options={extraOralExamOptions}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.extra_oral_exams == null
                ? []
                : patientRecord.tooths[selectedTooth].extra_oral_exams.map(
                    (el) => el.id
                  )
            }
            handleChange={extraOralExamsChanged}
          />
          <TextArea
            id="extra_oral_additional_notes"
            label="Additional Notes"
            placeholder="write here"
            span="1"
            value={
              patientRecord.tooths[selectedTooth]?.extra_oral_exam_notes == null
                ? ""
                : patientRecord.tooths[selectedTooth].extra_oral_exam_notes
            }
            handleTextChange={handleAdditionalNotesChange}
          />
        </Subsection>
        <Subsection>
          <div>RADIOGRAPHIC FINDINGS</div>
          <MultipleOptions
            id="radiographic_findings"
            label={null}
            span="1"
            options={radiographicFindingsOptions}
            selectedIds={
              patientRecord.tooths[selectedTooth]?.radiographic_findings == null
                ? []
                : patientRecord.tooths[selectedTooth].radiographic_findings.map(
                    (el) => el.id
                  )
            }
            handleChange={handleRadiographicFindingsChange}
          />
          <Checkbox
            id="oral_cancer_screening_done"
            label="ORAL CANCER SCREENING DONE"
            span="1"
            checked={
              patientRecord.tooths[selectedTooth]?.oral_cancer_screening_done
            }
            handleChange={handleOralCancerScreeningChange}
          />
          <TextArea
            id="radiographic_findings_additional_notes"
            label="Additional Notes"
            placeholder="write here"
            span="1"
            value={
              patientRecord.tooths[selectedTooth]
                ?.radiographic_findings_notes == null
                ? ""
                : patientRecord.tooths[selectedTooth]
                    .radiographic_findings_notes
            }
            handleTextChange={handleRadiographicFindingsNotesChange}
          />
        </Subsection>
      </div>
    </div>
  );
}

export function ToothDeleteButton() {
  return (
    <button className="tooth-delete-button">
      <img src={deleteIcon} alt="" />
    </button>
  );
}
