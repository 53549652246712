import dentistIllustration from "../../images/dentist_illustration.png";
import "./admin_login.css";
import { ActionButton } from "../Shared/ActionButton";
import { useAuth0 } from "@auth0/auth0-react";

export function AdminLogin() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  return (
    <div style={{ margin: "auto" }} className="container">
      <div className="admin-login-content">
        <div className="admin-login-headers">
          <h1>HELLO ADMIN</h1>
          <h2>MicroSurgical Endo</h2>
        </div>
        <div className="admin-login-illustration">
          <img
            src={dentistIllustration}
            alt="background gradient"
            className="qr-image"
            style={{ display: "block", margin: "auto" }}
          />
        </div>
        <div className={`span-1 button-action-container`}>
          {!isAuthenticated && (
            <ActionButton
              id="login"
              label="LOG IN"
              span="1"
              handleClick={loginWithRedirect}
            />
          )}
          {isAuthenticated && (
            <ActionButton
              id="logout"
              label="LOG OUT"
              span="1"
              handleClick={logout}
            />
          )}
        </div>
      </div>
    </div>
  );
}
