import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export function TodaysAppointmentsRow({ patientRecord }) {
  const [patientTime, setPatientTime] = useState(null);

  useEffect(
    function () {
      let datejs = dayjs(patientRecord.appointment_date);
      let hour = datejs.format("hh:mm A");
      setPatientTime(`${hour}`);
    },
    [patientRecord]
  );

  return (
    <div style={{ paddingRight: "30px", paddingLeft: "30px" }}>
      <div className="todays-appointments-row">
        <div>{patientTime}</div>
        <div>{patientRecord.patient_name}</div>
        <Link to={`/assistant_form/${patientRecord.id}`}>
          <button>VIEW PATIENT</button>
        </Link>
      </div>
      <div className="admin-dashboard-header-divider"></div>
    </div>
  );
}
