import { flushSync } from "react-dom";

export function ToothTab({
  setSelectedTooth,
  patientRecord,
  handlePatientRecordChange,
  teethState,
  setTeethState,
}) {
  function setToothInPatientRecord(toothNumber) {
    const newTooth = patientRecord.tooths.filter(
      (el) => el.number === toothNumber
    )[0] ?? { number: toothNumber };
    if (patientRecord.tooths.some((el) => el.number === toothNumber)) {
      const selectedIndex = patientRecord.tooths.findIndex(
        (el) => el.number === toothNumber
      );
      setSelectedTooth(selectedIndex);
    } else {
      const newTeeth = [...patientRecord.tooths, newTooth];
      const newPatientRecord = { ...patientRecord, tooths: newTeeth };
      flushSync(() => {
        handlePatientRecordChange(newPatientRecord);
      });
      const selectedIndex = newPatientRecord.tooths.findIndex(
        (el) => el.number === toothNumber
      );
      setSelectedTooth(selectedIndex);
    }
  }

  function handleToothClick(toothNumber) {
    const currentToothState = teethState[toothNumber - 1];
    let newCurrentTeethState;
    if (!currentToothState.selected && !currentToothState.active) {
      const newCurrentToothState = {
        number: toothNumber,
        selected: true,
        active: true,
      };
      setToothInPatientRecord(toothNumber);
      newCurrentTeethState = teethState.map((el) =>
        el.number === toothNumber
          ? newCurrentToothState
          : { ...el, active: false }
      );
    } else if (currentToothState.selected && !currentToothState.active) {
      const newCurrentToothState = {
        number: toothNumber,
        selected: true,
        active: true,
      };
      setToothInPatientRecord(toothNumber);
      newCurrentTeethState = teethState.map((el) =>
        el.number === toothNumber
          ? newCurrentToothState
          : { ...el, active: false }
      );
    } else if (currentToothState.selected && currentToothState.active) {
      return;
    } else if (!currentToothState.selected && currentToothState.active) {
      //not going to happen
    }
    setTeethState(newCurrentTeethState);
  }

  function calculateFillColor(active, selected) {
    if (active) return "#1D88F4";
    if (selected) return "#92C7FB";
    return "#D5DDE5";
  }

  return (
    <svg viewBox="0 0 1094 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Teeth" clipPath="url(#clip0_1447_27788)">
        <rect width="1094" height="300" fill="#202126" />
        <path
          id="Lower jaw"
          opacity="0.3"
          d="M1024.09 43.1004C1034.48 16.0509 1066.65 -12.5355 1028.47 -28.007C1007.24 -36.6136 1010.09 -14.2773 1004.73 -0.445166C998.491 15.846 972.782 39.9241 959.545 21.2764C950.027 7.8541 931.429 2.93602 923.443 7.95657C906.267 18.8173 928.804 51.6046 931.21 98.8388C932.304 121.482 925.303 152.118 912.503 170.049C899.703 187.979 774.878 198.737 696.329 204.987C629.485 210.213 575.442 206.934 558.594 206.934C541.637 206.934 486.828 210.315 419.984 204.987C341.326 198.84 216.5 187.979 203.81 170.049C191.01 152.118 184.008 121.482 185.102 98.8388C187.4 51.6046 209.936 18.8173 192.87 7.95657C184.884 2.83356 166.286 7.8541 156.768 21.2764C143.53 39.8217 117.821 15.7435 111.586 -0.445166C106.334 -14.2773 109.069 -36.6136 87.8458 -28.007C49.6652 -12.5355 81.8288 16.0509 92.2218 43.1004C113.555 98.7363 89.5962 152.63 84.7826 165.95C70.9982 203.86 93.8628 255.193 147.797 261.955C191.448 267.386 399.089 328.759 445.803 332.038C490.985 335.214 511.333 336.751 558.594 336.854C605.964 336.751 625.437 335.214 670.51 332.038C717.224 328.759 924.975 267.386 968.516 261.955C1022.45 255.295 1045.42 203.963 1031.53 165.95C1026.72 152.63 1002.76 98.7363 1024.09 43.1004Z"
          fill="#D4DEFF"
        />
        <g id="Lower jaw shaddow 8" opacity="0.2">
          <path
            id="Vector"
            opacity="0.0476"
            d="M384.321 316.156C384.321 316.156 413.093 302.837 437.818 269.435C462.542 236.033 468.012 209.905 468.012 209.905L678.388 207.856C678.388 207.856 674.559 275.48 741.184 315.951C741.184 315.951 661.541 336.853 557.72 336.853C453.9 336.853 384.321 316.156 384.321 316.156Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_2"
            opacity="0.0952"
            d="M387.604 316.976C387.604 316.976 415.829 302.837 440.116 269.435C464.402 236.033 469.763 209.905 469.763 209.905L676.31 207.856C676.31 207.856 672.59 276.299 737.902 316.771C737.902 316.771 659.681 336.956 557.83 336.956C455.979 336.956 387.604 316.976 387.604 316.976Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_3"
            opacity="0.1429"
            d="M390.776 317.796C390.776 317.796 418.455 302.837 442.304 269.435C466.153 236.033 471.404 209.905 471.404 209.905L674.122 207.856C674.122 207.856 670.403 277.017 734.621 317.488C734.621 317.488 657.822 336.956 557.83 336.956C457.948 336.853 390.776 317.796 390.776 317.796Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_4"
            opacity="0.1905"
            d="M394.054 318.615C394.054 318.615 421.185 302.836 444.596 269.332C468.008 235.93 473.15 209.803 473.15 209.803L672.148 207.753C672.148 207.753 668.538 277.631 731.443 318.205C731.443 318.205 656.067 336.853 557.935 336.853C459.913 336.853 394.054 318.615 394.054 318.615Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_5"
            opacity="0.2381"
            d="M397.228 319.435C397.228 319.435 423.921 302.836 446.786 269.332C469.76 235.93 474.792 209.803 474.792 209.803L669.962 207.753C669.962 207.753 666.461 278.451 728.163 318.923C728.163 318.923 654.208 336.853 558.046 336.853C461.883 336.853 397.228 319.435 397.228 319.435Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_6"
            opacity="0.2857"
            d="M400.518 320.255C400.518 320.255 426.664 302.836 449.091 269.332C471.628 235.93 476.551 209.803 476.551 209.803L667.891 207.753C667.891 207.753 664.39 279.168 724.998 319.64C724.998 319.64 652.466 336.853 558.163 336.853C463.86 336.853 400.518 320.255 400.518 320.255Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_7"
            opacity="0.3333"
            d="M403.68 321.074C403.68 321.074 429.279 302.836 451.378 269.332C473.367 235.93 478.29 209.803 478.29 209.803L665.802 207.753C665.802 207.753 662.411 279.885 721.705 320.46C721.705 320.46 650.705 336.853 558.152 336.853C465.819 336.853 403.68 321.074 403.68 321.074Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_8"
            opacity="0.381"
            d="M406.971 321.894C406.971 321.894 432.023 302.734 453.685 269.332C475.236 235.93 480.05 209.803 480.05 209.803L663.842 207.753C663.842 207.753 660.56 280.705 718.651 321.177C718.651 321.177 649.073 336.853 558.49 336.853C467.797 336.853 406.971 321.894 406.971 321.894Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_9"
            opacity="0.4286"
            d="M410.144 322.817C410.144 322.817 434.759 302.838 455.873 269.436C476.987 236.034 481.691 209.906 481.691 209.906L661.654 207.857C661.654 207.857 658.372 281.526 715.37 321.998C715.37 321.998 647.213 336.957 558.49 336.957C469.767 336.854 410.144 322.817 410.144 322.817Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_10"
            opacity="0.4762"
            d="M413.422 323.636C413.422 323.636 437.49 302.837 458.166 269.435C478.843 236.033 483.438 209.905 483.438 209.905L659.572 207.856C659.572 207.856 656.399 282.345 712.084 322.816C712.084 322.816 645.35 336.956 558.486 336.956C471.623 336.853 413.422 323.636 413.422 323.636Z"
            fill="#D4DEFF"
          />
          <path
            id="Vector_11"
            opacity="0.5238"
            d="M416.595 324.456C416.595 324.456 440.116 302.837 460.355 269.435C480.594 236.033 485.079 209.905 485.079 209.905L657.384 207.856C657.384 207.856 654.321 283.062 708.802 323.534C708.802 323.534 643.49 336.956 558.596 336.956C473.592 336.853 416.595 324.456 416.595 324.456Z"
            fill="#D4DEFF"
          />
        </g>
        <a href="#0" onClick={(e) => handleToothClick(32)}>
          <path
            id="Thirtytwo"
            d="M869.073 250.07C871.48 259.189 876.841 268.206 878.263 267.283C879.547 266.481 879.669 259.592 879.794 252.492C879.893 246.895 879.994 241.166 880.67 238.185C880.984 236.566 881.311 234.448 881.663 232.162C881.851 230.948 882.045 229.686 882.249 228.426C882.698 230.839 883.201 233.423 883.731 235.932C884.125 237.698 884.524 239.617 884.928 241.556C886.537 249.293 888.217 257.367 889.966 257.449C892.045 257.551 893.795 243.104 894.233 235.625C894.301 234.461 894.414 233.134 894.557 231.723C896.216 236.331 897.677 241.099 898.502 244.742C898.573 245.074 898.643 245.399 898.712 245.715C900.727 255.07 901.305 257.751 903.315 257.652C905.394 257.652 908.567 244.537 907.691 234.086C907.318 229.632 906.409 224.639 905.556 219.955C904.407 213.647 903.36 207.899 903.862 204.783C904.164 202.909 905.026 200.243 906.044 197.095C907.975 191.12 910.468 183.409 910.755 176.094C911.192 164.926 908.895 156.831 901.127 155.704C890.406 154.167 884.28 158.471 884.28 158.471C884.28 158.471 870.933 155.294 861.306 158.266C856.055 159.905 854.742 171.995 856.711 181.729C857.844 187.327 860.387 194.79 862.49 200.958C864.044 205.515 865.356 209.366 865.682 211.238C865.947 212.762 866.146 216.79 866.394 221.784C866.861 231.221 867.499 244.107 869.073 250.07Z"
            fill={calculateFillColor(
              teethState[31].active,
              teethState[31].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(31)}>
          <path
            id="Thirtyone"
            d="M824.988 262.776C823.566 263.698 818.862 258.165 815.908 249.251C812.954 240.337 810.11 215.644 809.125 211.238C808.141 206.935 801.686 192.898 799.279 183.267C796.763 173.635 799.826 159.496 805.078 157.959C813.173 155.602 826.848 158.574 826.848 158.574C826.848 158.574 834.944 155.295 842.711 154.987C850.588 154.68 855.402 164.516 855.62 175.684C855.839 186.853 849.932 198.226 849.275 203.759C848.728 209.291 851.354 227.324 852.338 237.775C853.323 248.226 849.166 263.288 847.087 263.288C845.009 263.288 845.227 256.935 843.039 246.792C840.742 236.648 833.85 214.107 831.115 214.414C828.38 214.619 827.723 229.988 826.739 236.853C825.754 243.718 827.286 261.239 824.988 262.776Z"
            fill={calculateFillColor(
              teethState[30].active,
              teethState[30].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(30)}>
          <path
            id="Thirty"
            d="M751.188 247.613C753.157 256.732 757.205 262.777 758.737 261.957C760.181 261.158 760.916 254.494 761.651 247.83C762.026 244.431 762.401 241.031 762.87 238.41C764.288 248.124 768.886 256.112 770.003 255.502C771.601 254.603 772.148 250.043 772.882 243.937C773.152 241.683 773.448 239.218 773.832 236.649C774.27 233.743 775.218 230.108 776.265 226.384C778.224 232.182 780.018 238.882 780.836 243.617C781.479 247.382 781.812 250.635 782.079 253.245C782.538 257.735 782.803 260.318 784.118 260.318C786.196 260.318 791.01 245.563 790.682 235.01C790.533 231.428 790.208 227.505 789.891 223.671C789.273 216.209 788.683 209.089 789.478 205.502C789.875 203.568 791.079 200.919 792.499 197.795C794.992 192.311 798.15 185.362 798.777 178.247C799.762 167.079 796.042 156.935 788.165 156.423C780.398 156.013 767.708 158.472 767.708 158.472C767.708 158.472 755.892 154.886 750.422 155.808C744.952 156.73 740.467 170.563 741.889 180.399C742.724 186.17 745.14 193.388 747.105 199.258C748.489 203.392 749.649 206.857 749.875 208.678C750.065 210.207 750.044 214.254 750.018 219.272C749.969 228.712 749.902 241.592 751.188 247.613Z"
            fill={calculateFillColor(
              teethState[29].active,
              teethState[29].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(29)}>
          <path
            id="Twentynine"
            d="M722.264 153.963C716.247 154.271 701.697 157.344 701.806 163.185C701.807 163.212 701.81 163.241 701.814 163.269C701.811 163.275 701.808 163.28 701.805 163.285C700.711 165.642 703.446 176.708 706.51 186.954C707.522 190.302 707.743 193.028 708.072 197.076C708.276 199.592 708.522 202.62 709.026 206.626C710.339 217.077 711.87 228.655 713.73 240.643C715.699 253.655 717.559 264.516 720.622 264.516C726.202 264.516 730.687 240.848 732 224.659C733.313 208.47 735.172 196.892 738.236 187.363C741.084 178.649 743.108 166.694 742.592 163.017C742.605 162.935 742.612 162.855 742.612 162.775C742.612 157.447 729.593 153.553 722.264 153.963Z"
            fill={calculateFillColor(
              teethState[28].active,
              teethState[28].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(28)}>
          <path
            id="Twentyeight"
            d="M670.194 242.515C673.074 254.107 675.66 264.52 678.981 264.52C684.998 264.52 688.061 240.749 689.593 224.458C691.124 208.167 692.218 196.692 695.61 187.163C698.572 178.666 701.691 167.088 701.52 163.107C701.536 162.995 701.537 162.884 701.521 162.775C700.865 158.062 695.176 155.808 691.457 155.398C689.823 155.223 688.249 155.645 686.438 156.131C686.061 156.232 685.673 156.336 685.273 156.437C683.716 155.639 681.959 154.984 679.965 154.683C677.619 154.328 674.855 155.114 672.124 156.293C671.458 156.117 670.929 155.944 670.455 155.789C669.314 155.415 668.49 155.145 666.842 155.193C662.356 155.295 654.261 158.062 653.932 163.185C653.929 163.25 653.937 163.316 653.954 163.384C652.99 166.106 655.989 176.87 659.508 186.855C661.063 191.397 661.547 194.793 662.56 201.897C662.758 203.287 662.977 204.818 663.227 206.528C664.759 217.081 667.056 229.889 670.01 241.774C670.072 242.022 670.133 242.269 670.194 242.515Z"
            fill={calculateFillColor(
              teethState[27].active,
              teethState[27].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(27)}>
          <path
            id="Twentyseven"
            d="M639.183 265.545C636.339 266.159 632.619 253.659 629.556 240.134C625.508 222.511 624.852 209.499 624.414 203.761C623.758 196.486 617.084 174.253 617.85 166.773C618.178 163.392 633.494 153.761 636.667 154.17C639.84 154.478 652.202 162.572 653.186 165.339C656.359 174.662 650.561 195.564 650.233 204.683C649.904 213.905 648.92 228.966 648.154 237.471C647.279 245.872 642.137 264.827 639.183 265.545Z"
            fill={calculateFillColor(
              teethState[26].active,
              teethState[26].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(26)}>
          <path
            id="Twentysix"
            d="M603.747 263.394C599.808 263.701 597.292 248.947 594.885 232.553C593.244 222 591.603 206.938 589.962 199.254C587.665 188.803 585.805 166.467 588.431 160.319C589.962 156.733 613.593 157.348 615.781 159.294C618.297 161.139 618.953 178.25 616.437 197.922C613.921 217.594 613.483 227.943 611.952 239.008C610.42 249.152 607.357 263.189 603.747 263.394Z"
            fill={calculateFillColor(
              teethState[25].active,
              teethState[25].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(25)}>
          <path
            id="Twentyfive"
            d="M572.8 265.337C568.533 265.644 563.939 249.045 561.313 232.447C559.891 223.635 558.359 207.037 557.046 199.25C554.968 187.877 552.561 167.897 555.405 160.007C556.718 156.319 582.318 157.036 584.615 158.983C588.116 161.954 587.241 180.602 585.272 197.918C584.943 216.873 582.974 228.041 581.333 239.209C579.802 249.455 576.52 265.029 572.8 265.337Z"
            fill={calculateFillColor(
              teethState[24].active,
              teethState[24].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(24)}>
          <path
            id="Twentyfour"
            d="M539.884 265.337C535.617 265.644 531.023 249.045 528.397 232.447C526.975 223.635 525.443 207.037 524.13 199.25C522.052 187.877 519.645 167.897 522.489 160.007C523.802 156.319 549.402 157.036 551.699 158.983C555.2 161.954 554.325 180.602 552.356 197.918C552.027 216.873 550.058 228.041 548.417 239.209C546.886 249.455 543.604 265.029 539.884 265.337Z"
            fill={calculateFillColor(
              teethState[23].active,
              teethState[23].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(23)}>
          <path
            id="Twnetythree"
            d="M507.071 263.394C503.132 263.701 500.616 248.947 498.209 232.553C496.568 222 494.927 206.938 493.286 199.254C490.989 188.803 489.129 166.467 491.755 160.319C493.286 156.733 516.917 157.348 519.105 159.294C521.621 161.139 522.277 178.25 519.761 197.922C517.245 217.594 516.807 227.943 515.276 239.008C513.744 249.152 510.681 263.189 507.071 263.394Z"
            fill={calculateFillColor(
              teethState[22].active,
              teethState[22].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(22)}>
          <path
            id="Twentytwo"
            d="M476.002 265.545C473.158 266.159 469.438 253.659 466.375 240.134C462.327 222.511 461.671 209.499 461.233 203.761C460.577 196.486 453.904 174.253 454.669 166.773C454.998 163.392 470.314 153.761 473.486 154.17C476.659 154.478 489.021 162.572 490.006 165.339C493.178 174.662 487.38 195.564 487.052 204.683C486.724 213.905 485.739 228.966 484.973 237.471C484.098 245.872 478.956 264.827 476.002 265.545Z"
            fill={calculateFillColor(
              teethState[21].active,
              teethState[21].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(21)}>
          <path
            id="Twentyone"
            d="M423.457 242.516C426.336 254.107 428.923 264.52 432.243 264.52C438.26 264.52 441.324 240.749 442.855 224.458C444.387 208.167 445.481 196.692 448.872 187.163C451.835 178.666 454.953 167.089 454.782 163.107C454.799 162.995 454.8 162.884 454.784 162.775C454.128 158.062 448.439 155.808 444.719 155.398C443.086 155.223 441.512 155.645 439.7 156.131C439.323 156.232 438.936 156.336 438.536 156.437C436.979 155.639 435.221 154.984 433.228 154.683C430.882 154.328 428.117 155.114 425.387 156.293C424.721 156.117 424.192 155.944 423.718 155.789C422.577 155.415 421.753 155.145 420.104 155.193C415.619 155.295 407.523 158.062 407.195 163.185C407.192 163.25 407.199 163.316 407.217 163.384C406.253 166.106 409.252 176.87 412.77 186.855C414.326 191.397 414.81 194.793 415.823 201.897C416.021 203.287 416.239 204.818 416.49 206.528C418.021 217.081 420.319 229.889 423.273 241.774C423.334 242.022 423.396 242.269 423.457 242.516Z"
            fill={calculateFillColor(
              teethState[20].active,
              teethState[20].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(20)}>
          <path
            id="Twenty"
            d="M406.454 163.233C406.886 167.143 404.712 178.813 401.824 187.363C398.542 196.892 396.463 208.47 395.041 224.659C393.728 240.848 388.805 264.516 382.788 264.516C379.506 264.516 377.537 253.655 375.349 240.643C373.714 230.686 372.304 221.011 370.996 212.031C370.729 210.199 370.467 208.396 370.207 206.626C369.664 202.636 369.392 199.617 369.166 197.106C368.8 193.041 368.554 190.311 367.472 186.954C364.19 176.708 361.237 165.642 362.331 163.285C362.344 163.255 362.382 163.215 362.441 163.167C362.364 157.338 378.092 154.27 384.54 153.963C392.417 153.553 406.529 157.447 406.529 162.775C406.529 162.925 406.504 163.078 406.454 163.233Z"
            fill={calculateFillColor(
              teethState[19].active,
              teethState[19].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(19)}>
          <path
            id="Nineteen"
            d="M315.404 247.613C317.373 256.732 321.421 262.777 322.953 261.957C324.397 261.158 325.132 254.494 325.867 247.83C326.242 244.431 326.617 241.031 327.086 238.41C328.503 248.124 333.102 256.112 334.218 255.502C335.817 254.603 336.364 250.043 337.097 243.937C337.368 241.683 337.664 239.218 338.047 236.649C338.486 233.743 339.433 230.108 340.48 226.384C342.44 232.182 344.234 238.882 345.051 243.617C345.695 247.382 346.028 250.635 346.295 253.245C346.754 257.735 347.019 260.318 348.333 260.318C350.412 260.318 355.226 245.563 354.897 235.01C354.749 231.428 354.424 227.505 354.106 223.671C353.489 216.209 352.899 209.089 353.694 205.502C354.091 203.568 355.295 200.919 356.715 197.795C359.207 192.311 362.366 185.362 362.993 178.247C363.978 167.079 360.258 156.935 352.381 156.423C344.614 156.013 331.923 158.472 331.923 158.472C331.923 158.472 320.108 154.886 314.638 155.808C309.168 156.73 304.683 170.563 306.105 180.399C306.939 186.17 309.356 193.388 311.321 199.258C312.705 203.392 313.865 206.857 314.091 208.678C314.281 210.207 314.26 214.254 314.234 219.272C314.185 228.712 314.118 241.592 315.404 247.613Z"
            fill={calculateFillColor(
              teethState[18].active,
              teethState[18].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(18)}>
          <path
            id="Eighteen"
            d="M265.071 261.855C263.54 262.674 259.601 256.629 257.741 247.408C255.882 238.186 256.319 213.699 255.772 209.293C255.335 204.887 250.63 189.928 249.427 180.092C248.224 170.255 252.928 156.628 258.398 155.604C266.822 154.169 279.95 158.575 279.95 158.575C279.95 158.575 288.373 156.218 296.141 156.731C303.908 157.345 307.628 167.591 306.424 178.76C305.221 189.928 297.891 200.481 296.688 205.912C295.375 211.342 295.813 229.58 295.484 240.031C295.047 250.584 289.139 264.929 287.061 264.826C284.982 264.621 286.076 258.269 285.091 248.023C284.107 237.777 279.95 214.621 277.215 214.518C274.48 214.416 271.854 229.58 270.104 236.342C268.244 243.002 267.478 260.625 265.071 261.855Z"
            fill={calculateFillColor(
              teethState[17].active,
              teethState[17].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(17)}>
          <path
            id="Seventeen"
            d="M192.432 246.279C192.979 255.603 196.261 265.439 197.902 264.824C199.386 264.304 200.974 257.471 202.607 250.442C203.865 245.029 205.15 239.501 206.435 236.648C207.012 235.23 207.689 233.407 208.423 231.43C208.885 230.187 209.369 228.883 209.865 227.58C209.82 229.99 209.8 232.575 209.83 235.113C209.852 236.913 209.848 238.864 209.843 240.837C209.826 248.748 209.807 257.019 211.471 257.347C213.55 257.756 218.144 244.027 220.114 236.65C220.437 235.454 220.85 234.086 221.313 232.635C222.014 237.546 222.477 242.6 222.517 246.382L222.522 246.846C222.627 256.816 222.656 259.604 224.596 259.906C226.674 260.214 232.582 248.021 233.785 237.57C234.282 233.254 234.425 228.362 234.559 223.738C234.751 217.163 234.927 211.129 236.083 208.062C236.772 206.233 238.198 203.714 239.877 200.747C242.998 195.235 246.995 188.175 248.773 181.115C251.508 170.254 250.961 161.852 243.522 159.393C233.348 155.909 226.456 159.086 226.456 159.086C226.456 159.086 213.984 153.553 203.919 154.68C198.34 155.295 194.62 166.975 194.511 176.914C194.446 182.759 195.404 190.801 196.181 197.331C196.725 201.904 197.181 205.735 197.136 207.549C197.064 209.047 196.459 212.797 195.705 217.479C194.189 226.885 192.067 240.054 192.432 246.279Z"
            fill={calculateFillColor(
              teethState[16].active,
              teethState[16].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(16)}>
          <path
            id="Sixteen"
            d="M224.819 36.9562C229.195 28.5545 236.524 20.87 237.728 21.9971C238.84 23.0384 237.371 29.8945 235.861 36.9395C234.697 42.3713 233.509 47.9154 233.461 50.9933C233.389 52.5007 233.238 54.4232 233.074 56.5122C232.968 57.8588 232.856 59.2746 232.757 60.693C233.76 58.4332 234.859 56.0124 235.977 53.6576C236.624 52.2931 237.302 50.8345 237.99 49.3509C241.49 41.8107 245.289 33.6259 247.026 33.8828C249.105 34.2926 247.464 48.6371 246.151 56.0142C245.94 57.1981 245.738 58.564 245.547 60.0231C248.222 55.8566 250.74 51.5034 252.387 48.1244C252.513 47.8607 252.636 47.6023 252.756 47.3492C256.941 38.5515 258.131 36.0484 260.045 36.5464C262.015 36.9562 262.233 50.3785 258.951 60.4196C257.569 64.6478 255.547 69.2758 253.645 73.6301C251.029 79.6164 248.639 85.0858 248.449 88.2888C248.296 90.2228 248.531 93.0457 248.807 96.3723C249.322 102.563 249.982 110.497 248.558 117.695C246.37 128.658 242.323 136.138 234.446 135.625C223.725 134.908 218.692 129.478 218.692 129.478C218.692 129.478 204.908 129.888 196.156 125.072C191.342 122.408 192.874 110.318 197.031 101.199C199.438 95.9199 203.677 89.0616 207.161 83.4264C209.695 79.3272 211.829 75.8752 212.566 74.1493C213.169 72.7373 214.278 68.8811 215.657 64.0888C218.28 54.9706 221.878 42.4635 224.819 36.9562Z"
            fill={calculateFillColor(
              teethState[15].active,
              teethState[15].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(15)}>
          <path
            id="Fifteen"
            d="M277.762 31.7295C276.34 30.7049 271.527 36.1353 268.245 44.9469C264.963 53.7585 261.352 78.0415 260.149 82.3449C258.946 86.6482 251.944 100.788 249.1 110.316C246.255 119.845 248.771 134.087 254.023 135.727C262.118 138.391 275.793 135.829 275.793 135.829C275.793 135.829 283.779 139.415 291.547 139.927C299.314 140.44 304.565 130.809 305.222 119.64C305.878 108.472 300.299 96.8942 299.971 91.4638C299.533 85.931 302.924 68.0004 304.237 57.5495C305.55 47.0985 301.94 32.0369 299.861 31.832C297.783 31.7295 297.783 38.1845 295.157 48.2256C292.531 58.2667 284.764 80.5006 282.029 80.1932C279.294 79.8858 279.185 64.5168 278.528 57.6519C277.872 50.8896 279.95 33.3689 277.762 31.7295Z"
            fill={calculateFillColor(
              teethState[14].active,
              teethState[14].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(14)}>
          <path
            id="Fourteen"
            d="M315.222 49.1839C317.192 39.9625 321.239 34.0198 322.771 34.8395C324.215 35.6387 324.95 42.3027 325.685 48.9667C326.076 52.5099 326.467 56.0531 326.965 58.7148C328.324 48.8527 333.014 40.6771 334.144 41.2939C335.681 42.2035 336.261 46.8699 337.034 53.0946C337.306 55.2843 337.602 57.6669 337.973 60.1466C338.404 63.0074 339.33 66.5759 340.357 70.2393C342.294 64.4812 344.061 57.8676 344.87 53.1798C345.513 49.4144 345.846 46.161 346.113 43.5518C346.573 39.0612 346.837 36.4788 348.152 36.4788C350.23 36.4788 355.044 51.2331 354.716 61.7865C354.567 65.3682 354.242 69.2921 353.925 73.1257C353.307 80.5878 352.717 87.7076 353.512 91.295C353.909 93.2286 355.113 95.8773 356.533 99.0013C359.026 104.486 362.184 111.434 362.811 118.549C363.796 129.718 360.076 139.861 352.2 140.373C344.432 140.783 331.742 138.324 331.742 138.324C331.742 138.324 319.927 141.91 314.457 140.988C308.987 140.066 304.501 126.234 305.923 116.398C306.758 110.626 309.174 103.409 311.139 97.539C312.523 93.4049 313.684 89.9395 313.91 88.1188C314.099 86.5897 314.078 82.543 314.052 77.525C314.003 68.0841 313.937 55.2049 315.222 49.1839Z"
            fill={calculateFillColor(
              teethState[13].active,
              teethState[13].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(13)}>
          <path
            id="Thriteen"
            d="M374.825 56.6252C376.794 43.6127 378.654 32.752 381.717 32.752C387.296 32.752 391.891 56.4203 393.094 72.609C394.407 88.7977 396.267 100.376 399.33 109.904C402.179 118.619 404.203 130.575 403.687 134.252C403.7 134.333 403.707 134.414 403.707 134.494C403.707 139.821 390.688 143.715 383.358 143.305C377.341 142.998 362.791 139.924 362.901 134.084C362.902 134.055 362.905 134.027 362.909 133.998C362.905 133.993 362.902 133.988 362.9 133.983C361.806 131.626 364.541 120.56 367.604 110.314C368.616 106.965 368.838 104.24 369.167 100.192C369.371 97.6755 369.617 94.648 370.12 90.642C371.433 80.191 372.965 68.613 374.825 56.6252Z"
            fill={calculateFillColor(
              teethState[12].active,
              teethState[12].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(12)}>
          <path
            id="Twelve"
            d="M418.702 54.4831C421.341 43.0016 423.697 32.752 426.673 32.752C432.143 32.752 434.878 56.5227 436.191 72.8139L436.223 73.1849C437.628 89.2796 438.621 100.653 441.661 110.109C444.837 119.827 448.116 133.575 446.836 135.38C445.703 139.491 441.001 141.487 437.829 141.871C436.389 142.045 434.989 141.628 433.368 141.145C433.008 141.038 432.638 140.927 432.254 140.82C430.843 141.625 429.251 142.286 427.439 142.589C425.326 142.943 422.823 142.163 420.347 140.989C419.766 141.161 419.302 141.329 418.885 141.481C417.856 141.854 417.114 142.124 415.621 142.076C411.464 141.973 404.134 139.207 403.915 134.084C403.915 134.082 403.915 134.081 403.915 134.079C402.829 131.71 405.671 120.654 408.95 110.417C410.529 105.194 410.994 101.487 412.195 91.9127C412.243 91.5327 412.292 91.1434 412.342 90.7444C413.655 80.191 415.733 67.3835 418.468 55.4981C418.546 55.1589 418.624 54.8204 418.702 54.4831Z"
            fill={calculateFillColor(
              teethState[11].active,
              teethState[11].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(11)}>
          <path
            id="Eleven"
            d="M466.107 31.7268C463.481 31.1121 460.09 43.6122 457.245 57.137C453.526 74.7601 452.979 87.7726 452.432 93.5104C451.885 100.785 445.649 123.019 446.415 130.498C446.743 133.88 460.855 143.511 463.7 143.101C466.544 142.794 477.922 134.699 478.797 131.933C481.641 122.609 476.39 101.707 476.062 92.5882C475.734 83.3668 474.859 68.3051 474.093 59.8009C473.655 51.3992 468.842 32.4441 466.107 31.7268Z"
            fill={calculateFillColor(
              teethState[10].active,
              teethState[10].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(10)}>
          <path
            id="Ten"
            d="M498.14 30.0876C492.779 29.4728 490.372 47.5058 487.637 64.1043C486.215 72.9159 484.355 82.2398 482.933 90.0268C481.62 97.8137 478.01 127.117 480.964 135.724C482.277 139.515 508.752 138.593 511.268 136.749C515.425 133.777 513.784 108.777 512.252 91.3587C509.408 77.4242 508.97 67.6904 507.22 56.5223C505.688 46.3787 502.078 30.4974 498.14 30.0876Z"
            fill={calculateFillColor(
              teethState[9].active,
              teethState[9].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(9)}>
          <path
            id="Nine"
            d="M536.166 22.9167C531.79 22.6093 526.758 37.7734 523.694 53.7572C521.944 62.6713 522.163 73.6345 519.537 83.983C518.771 87.6715 515.818 96.4831 514.942 104.373C513.52 117.385 513.739 131.012 514.942 135.008C516.474 139.824 548.419 138.902 551.154 136.34C553.014 134.598 553.889 121.278 552.795 106.832C552.248 99.5569 550.716 89.7207 549.622 85.4174C547.434 73.0197 546.778 56.0114 544.699 44.9457C543.058 35.3144 540.104 23.2241 536.166 22.9167Z"
            fill={calculateFillColor(
              teethState[8].active,
              teethState[8].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(8)}>
          <path
            id="Eight"
            d="M575.769 22.9167C571.393 22.6093 566.361 37.7734 563.298 53.7572C561.548 62.6713 561.766 73.6345 559.141 83.983C558.375 87.6715 555.421 96.4831 554.546 104.373C553.124 117.385 553.342 131.012 554.546 135.008C556.077 139.824 588.022 138.902 590.757 136.34C592.617 134.598 593.492 121.278 592.398 106.832C591.851 99.5569 590.32 89.7207 589.226 85.4174C587.038 73.0197 586.381 56.0114 584.303 44.9457C582.662 35.3144 579.817 23.2241 575.769 22.9167Z"
            fill={calculateFillColor(
              teethState[7].active,
              teethState[7].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(7)}>
          <path
            id="Seven"
            d="M611.001 30.0876C605.641 29.4728 603.234 47.5058 600.499 64.1043C599.077 72.9159 597.217 82.2398 595.795 90.0268C594.482 97.8137 590.872 127.117 593.825 135.724C595.138 139.515 621.613 138.593 624.129 136.749C628.286 133.777 626.645 108.777 625.114 91.3587C622.269 77.4242 621.832 67.6904 620.081 56.5223C618.55 46.3787 614.94 30.4974 611.001 30.0876Z"
            fill={calculateFillColor(
              teethState[6].active,
              teethState[6].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(6)}>
          <path
            id="Six"
            d="M646.446 31.7268C643.82 31.1121 640.429 43.6122 637.584 57.137C633.865 74.7601 633.318 87.7726 632.771 93.5104C632.224 100.785 625.988 123.019 626.754 130.498C627.082 133.88 641.194 143.511 644.039 143.101C646.883 142.794 658.261 134.699 659.136 131.933C661.98 122.609 656.729 101.707 656.401 92.5882C656.073 83.3668 655.198 68.3051 654.432 59.8009C653.994 51.3992 649.181 32.4441 646.446 31.7268Z"
            fill={calculateFillColor(
              teethState[5].active,
              teethState[5].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(5)}>
          <path
            id="Five"
            d="M674.029 54.4831C676.668 43.0016 679.025 32.752 682 32.752C687.47 32.752 690.205 56.5227 691.518 72.8139L691.551 73.1849C692.955 89.2796 693.948 100.653 696.988 110.109C700.164 119.827 703.443 133.575 702.164 135.38C701.03 139.491 696.328 141.487 693.157 141.871C691.717 142.045 690.316 141.628 688.695 141.145C688.335 141.038 687.965 140.927 687.581 140.82C686.17 141.625 684.578 142.286 682.766 142.589C680.653 142.943 678.15 142.163 675.674 140.989C675.093 141.161 674.629 141.329 674.212 141.481C673.184 141.854 672.442 142.124 670.948 142.076C666.791 141.973 659.461 139.207 659.243 134.084C659.243 134.082 659.242 134.081 659.242 134.079C658.156 131.71 660.998 120.654 664.278 110.417C665.856 105.194 666.321 101.487 667.522 91.9127C667.57 91.5327 667.619 91.1434 667.669 90.7444C668.982 80.191 671.06 67.3835 673.795 55.4981C673.873 55.1589 673.951 54.8204 674.029 54.4831Z"
            fill={calculateFillColor(
              teethState[4].active,
              teethState[4].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(4)}>
          <path
            id="Four"
            d="M714.168 56.6252C716.138 43.6127 717.997 32.752 721.061 32.752C726.64 32.752 731.235 56.4203 732.438 72.609C733.751 88.7977 735.611 100.376 738.674 109.904C741.522 118.619 743.547 130.575 743.031 134.252C743.044 134.333 743.051 134.414 743.051 134.494C743.051 139.821 730.032 143.715 722.702 143.305C716.685 142.998 702.135 139.924 702.244 134.084C702.246 134.055 702.248 134.027 702.252 133.998C702.249 133.993 702.246 133.988 702.244 133.983C701.15 131.626 703.885 120.56 706.948 110.314C707.96 106.965 708.181 104.24 708.51 100.192C708.715 97.6755 708.961 94.648 709.464 90.642C710.777 80.191 712.309 68.613 714.168 56.6252Z"
            fill={calculateFillColor(
              teethState[3].active,
              teethState[3].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(3)}>
          <path
            id="Three"
            d="M751.573 49.658C753.542 40.4366 757.59 34.4939 759.122 35.3136C760.566 36.1128 761.301 42.7768 762.036 49.4408C762.427 52.984 762.818 56.5272 763.315 59.1888C764.675 49.3268 769.365 41.1512 770.494 41.7681C772.032 42.6776 772.612 47.344 773.385 53.5687C773.657 55.7584 773.953 58.141 774.323 60.6207C774.755 63.4815 775.68 67.0501 776.708 70.7135C778.644 64.9554 780.411 58.3418 781.22 53.654C781.864 49.8886 782.197 46.6352 782.464 44.026C782.923 39.5353 783.188 36.953 784.502 36.953C786.581 36.953 791.394 51.7072 791.066 62.2606C790.918 65.8423 790.593 69.7662 790.275 73.5998C789.657 81.0619 789.068 88.1818 789.863 91.7692C790.26 93.7027 791.464 96.3515 792.884 99.4755C795.376 104.96 798.535 111.908 799.162 119.024C800.147 130.192 796.427 140.335 788.55 140.848C780.783 141.257 768.092 138.798 768.092 138.798C768.092 138.798 756.277 142.384 750.807 141.462C745.337 140.54 740.852 126.708 742.274 116.872C743.108 111.101 745.525 103.883 747.49 98.0131C748.874 93.879 750.034 90.4136 750.26 88.5929C750.45 87.0638 750.429 83.0172 750.403 77.9991C750.354 68.5582 750.287 55.6791 751.573 49.658Z"
            fill={calculateFillColor(
              teethState[2].active,
              teethState[2].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(2)}>
          <path
            id="Two"
            d="M807.917 34.9032C806.276 34.186 802.885 40.5385 802.01 49.8624C801.025 59.1863 803.541 83.8792 803.432 88.3875C803.323 92.7932 800.15 107.752 799.712 117.691C799.384 127.63 805.292 140.847 810.871 141.359C819.404 142.179 832.095 136.749 832.095 136.749C832.095 136.749 840.737 138.49 848.395 137.261C856.163 136.031 858.788 125.58 856.6 114.515C854.412 103.552 846.207 93.5105 844.457 88.1825C842.707 82.9571 841.503 64.7192 840.19 54.2682C838.878 43.8173 831.548 29.8827 829.579 30.2925C827.609 30.5999 829.141 36.9524 829.141 47.1985C829.141 57.5469 827.062 80.9079 824.327 81.2152C821.592 81.5226 817.654 66.5634 815.247 60.006C812.84 53.4485 810.433 35.9278 807.917 34.9032Z"
            fill={calculateFillColor(
              teethState[1].active,
              teethState[1].selected
            )}
          />
        </a>
        <a href="#0" onClick={(e) => handleToothClick(1)}>
          <path
            id="One"
            d="M847.956 45.1494C847.518 35.8255 849.925 25.7844 851.566 26.1943C853.145 26.6676 855.454 33.6315 857.776 40.6359C859.475 45.7621 861.182 50.91 862.615 53.5511C863.352 54.932 864.239 56.7265 865.2 58.6721C865.858 60.0062 866.552 61.4114 867.257 62.803C866.932 60.2243 866.605 57.4187 866.339 54.6794C866.192 53.1638 866.02 51.5469 865.846 49.9027C864.981 41.7649 864.045 32.9569 865.683 32.4455C867.652 31.8307 873.669 45.1506 876.404 52.2203C876.821 53.2977 877.327 54.513 877.884 55.7993C878.072 50.9594 878.025 46.0435 877.712 42.383C877.678 41.981 877.645 41.5904 877.612 41.2108C876.815 31.9155 876.586 29.248 878.478 28.7558C880.447 28.2435 887.558 39.9239 889.856 50.17C890.816 54.4963 891.452 59.4315 892.05 64.0772C892.882 70.5438 893.643 76.4495 895.107 79.3711C895.955 81.0296 897.538 83.2706 899.41 85.9214C903.089 91.1312 907.885 97.9237 910.423 104.986C914.252 115.642 914.471 123.941 907.36 127.118C897.623 131.523 890.403 128.962 890.403 128.962C890.403 128.962 878.697 135.622 868.523 135.314C862.943 135.109 858.02 123.839 856.926 114.003C856.283 108.161 856.434 100.089 856.556 93.5317C856.642 88.9336 856.714 85.0807 856.489 83.2646C856.294 81.6983 855.215 77.6068 853.885 72.5646C851.473 63.4238 848.238 51.1585 847.956 45.1494Z"
            fill={calculateFillColor(
              teethState[0].active,
              teethState[0].selected
            )}
          />
        </a>
      </g>
      <defs>
        <clipPath id="clip0_1447_27788">
          <rect width="1094" height="300" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
