import "./style/text_field.css";

export function TextField({
  id,
  label,
  placeholder,
  value,
  span,
  disabled,
  error,
  handleTextChange,
}) {
  return (
    <div className={`span-${span} text-field`}>
      <label className="form-label">
        {label}
        <input
          type="text"
          className={error ? "text-field-input error" : "text-field-input"}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={(e) => handleTextChange(e.target.value)}
        ></input>
      </label>
    </div>
  );
}
