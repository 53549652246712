import searchIconDark from "../../images/search_icon_dark.png";

export function SearchResultsHeader() {
  return (
    <div className="search-results-header">
      <img src={searchIconDark} alt="calendar icon" />
      <span>Search Results</span>
      <div className="admin-dashboard-header-divider"></div>
    </div>
  );
}
