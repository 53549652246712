export default function TabButton({
  children,
  current,
  filled,
  buttonOrder,
  onClick,
}) {
  let className = "";
  if (current) {
    className = "tab-button tab-button-current";
  }
  if (filled) {
    className = `${className} tab-button tab-button-filled`;
  }
  return (
    <button
      className={`${className} tab-button`}
      onClick={(e) => onClick(buttonOrder)}
    >
      {children}
    </button>
  );
}
