import "./style/text_area.css";

export function TextArea({
  id,
  label,
  placeholder,
  value,
  span,
  disabled,
  error,
  handleTextChange,
}) {
  return (
    <div className={`span-${span} text-area`}>
      <label className={disabled ? "form-label-disabled" : "form-label"}>
        {label}
        <textarea
          className={error ? "text-area-input error" : "text-area-input"}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleTextChange(e.target.value)}
          disabled={disabled}
        ></textarea>
      </label>
    </div>
  );
}
