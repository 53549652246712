import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export function SearchResultsRow({ patientRecord }) {
  const [patientTime, setPatientTime] = useState(null);
  const [patientDate, setPatientDate] = useState(null);

  useEffect(
    function () {
      if (patientRecord == null) {
        return;
      }
      let datejs = dayjs(patientRecord.appointment_date);
      let hour = datejs.format("hh:mm A");
      setPatientTime(`${hour}`);
      let patDate = datejs.format("MMMM DD, YYYY");
      setPatientDate(patDate);
    },
    [patientRecord]
  );

  return (
    <div style={{ paddingRight: "30px", paddingLeft: "30px" }}>
      <div className="search-results-row">
        <div className="search-results-row-datetime">
          <div className="search-results-row-time">{patientTime}</div>
          <div className="search-results-row-date">{patientDate}</div>
        </div>
        <div>{patientRecord.patient_name}</div>
        <Link to={`/assistant_form/${patientRecord.id}`}>
          <button>VIEW PATIENT</button>
        </Link>
      </div>
      <div className="admin-dashboard-header-divider"></div>
    </div>
  );
}
