export function YesNoButtons({ id, setting, setSetting, error, disabled }) {
  let errorString = error ? " error" : "";
  return (
    <div id={id}>
      <button
        className={
          setting === "yes"
            ? "button-yesno-option button-yesno-selected" + errorString
            : "button-yesno-option" + errorString
        }
        onClick={(e) => {
          e.preventDefault();
          if (setSetting === undefined) {
            return;
          }
          if (setting === "yes") {
            setSetting("none");
          } else {
            setSetting("yes");
          }
        }}
        disabled={disabled}
      >
        Yes
      </button>
      <button
        className={
          setting === "no"
            ? "button-yesno-option button-yesno-selected" + errorString
            : "button-yesno-option" + errorString
        }
        onClick={(e) => {
          e.preventDefault();
          if (setSetting === undefined) {
            return;
          }
          if (setting === "no") {
            setSetting("none");
          } else {
            setSetting("no");
          }
        }}
        disabled={disabled}
      >
        No
      </button>
    </div>
  );
}
