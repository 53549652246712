import "./style/date_field.css";
import { DatePicker } from "antd";

export function DateField({
  id,
  label,
  span,
  value,
  placeholder,
  showTime = false,
  handleChange,
  handleOk,
}) {
  return (
    <div className={`span-${span} date-field ${showTime ? "time" : ""}`}>
      <label className="form-label">{label}</label>
      <DatePicker
        showTime={showTime}
        showNow={false}
        format={showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
        minuteStep={15}
        secondStep={60}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onOk={handleOk}
        style={{ width: "100%", height: "50px", marginTop: "4px" }}
      />
    </div>
  );
}
