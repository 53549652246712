import "./style/diagnosis.css";
import arrowLeft from "../../../images/arrow-left.svg";
import { ActionButton } from "../../Shared/ActionButton";
import { MultipleOptions } from "../../Shared/MultipleOptions";

export function DiagnosisEdit({
  patientRecord,
  selectedTooth,
  handlePatientRecordChange,
  setShowEdit,
  pulpalOptions,
  periapicalOptions,
  nonodontogenicOptions,
  unexplainedOptions,
  savePatientRecord,
}) {
  function handleNewDiagnosis(newDiagnosis) {
    const newTooth = {
      ...patientRecord.tooths?.[selectedTooth],
      diagnosis: newDiagnosis,
    };
    const newTeeth = patientRecord.tooths?.map((el) =>
      el.number === newTooth.number ? newTooth : el
    );

    const newPatientRecord = { ...patientRecord, tooths: newTeeth };
    handlePatientRecordChange(newPatientRecord);
  }

  function handlePulpalDiagnoseChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newPulpals = array.map((el) => {
      let hash = {};
      hash.id = el;
      return hash;
    });
    const newDiagnosis = {
      ...(patientRecord.tooths?.[selectedTooth]?.diagnosis ?? {}),
      pulpals: newPulpals,
    };

    handleNewDiagnosis(newDiagnosis);
  }

  function handlePeriapicalDiagnoseChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newPeriapicals = array.map((el) => {
      let hash = {};
      hash.id = el;
      return hash;
    });
    const newDiagnosis = {
      ...(patientRecord.tooths?.[selectedTooth]?.diagnosis ?? {}),
      periapicals: newPeriapicals,
    };

    handleNewDiagnosis(newDiagnosis);
  }

  function handleNonodontogenicDiagnoseChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }
    const newNonodontogenics = array.map((el) => {
      let hash = {};
      hash.id = el;
      return hash;
    });
    const newDiagnosis = {
      ...(patientRecord.tooths?.[selectedTooth]?.diagnosis ?? {}),
      non_odontogenics: newNonodontogenics,
    };

    handleNewDiagnosis(newDiagnosis);
  }

  function handleUnexplainedDiagnoseChange(array) {
    if (patientRecord.tooths[selectedTooth] == null) {
      return;
    }

    const newDiagnosis = {
      ...(patientRecord.tooths?.[selectedTooth]?.diagnosis ?? {}),
      unexplained_at_this_time: array.length > 0,
    };

    handleNewDiagnosis(newDiagnosis);
  }

  return (
    <div className="diagnosis-edit">
      <button
        className="diagnosis-back-button"
        onClick={(e) => setShowEdit(false)}
      >
        <img src={arrowLeft} alt="left arrow" />
        <span> cancel edit and go back</span>
      </button>
      <div className="diagnosis-title">
        Editing diagnosis for TOOTH #
        {patientRecord.tooths?.[selectedTooth]?.number}
      </div>

      <MultipleOptions
        id="pulpal_diagnosis"
        label="Pulpal"
        span="1"
        options={pulpalOptions}
        selectedIds={
          patientRecord.tooths[selectedTooth]?.diagnosis?.pulpals == null
            ? []
            : patientRecord.tooths[selectedTooth].diagnosis?.pulpals.map(
                (el) => el.id
              )
        }
        handleChange={handlePulpalDiagnoseChange}
      />

      <MultipleOptions
        id="periapical_diagnosis"
        label="Periapical"
        span="1"
        options={periapicalOptions}
        selectedIds={
          patientRecord.tooths[selectedTooth]?.diagnosis?.periapicals == null
            ? []
            : patientRecord.tooths[selectedTooth].diagnosis?.periapicals.map(
                (el) => el.id
              )
        }
        handleChange={handlePeriapicalDiagnoseChange}
      />
      <MultipleOptions
        id="non_odontogenic_diagnosis"
        label="Non-odontogenic"
        span="1"
        options={nonodontogenicOptions}
        selectedIds={
          patientRecord.tooths[selectedTooth]?.diagnosis?.non_odontogenics ==
          null
            ? []
            : patientRecord.tooths[
                selectedTooth
              ].diagnosis?.non_odontogenics.map((el) => el.id)
        }
        handleChange={handleNonodontogenicDiagnoseChange}
      />
      <div className="diagnosis-edit-ruler">
        <hr />
      </div>
      <MultipleOptions
        id="non_odontogenic_diagnosis"
        label=""
        span="1"
        options={unexplainedOptions}
        selectedIds={
          patientRecord.tooths[selectedTooth]?.diagnosis
            ?.unexplained_at_this_time
            ? [1]
            : []
        }
        handleChange={handleUnexplainedDiagnoseChange}
      />
      <div className="diagnosis-action-button-container">
        <ActionButton
          id="edit_diagnosis"
          label="SAVE AND UPDATE DIAGNOSIS"
          span="1"
          handleClick={function () {
            savePatientRecord();
            setShowEdit(false);
          }}
        />
      </div>
    </div>
  );
}
