import { AppLayout } from "./AppLayout";
import { PatientFormContainer } from "./PatientForm/PatientFormContainer";
import { PatientLanding } from "./LandingScreen/PatientLanding";
import { PageNotFound } from "./PageNotFound/PageNotFound";
import { AdminDashboard } from "./AdminDashboard/AdminDashboard";
import { AssistantForm } from "./AssistantForm/AssistantForm";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PatientFinished } from "./PatientFinished/PatientFinished";
import { AdminLogin } from "./AdminLogin/AdminLogin";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { PageLoader } from "./PageLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export default function App() {
  const { isLoading } = useAuth0();
  //unprotected patient form options
  const [toothSensitivityOptions, setToothSensitivityOptions] = useState([]);
  const [workOptions, setWorkOptions] = useState([]);
  const [anxiousOptions, setAnxiousOptions] = useState([]);
  const [medicationsOptions, setMedicationsOptions] = useState([]);
  const [allergicOptions, setAllergicOptions] = useState([]);

  useEffect(function () {
    async function getToothSensitivites() {
      const res = await fetch(`${rootURL}/tooth_sensitivities`);
      const data = await res.json();
      setToothSensitivityOptions(data);
    }
    getToothSensitivites();
  }, []);

  useEffect(function () {
    async function getWorkDone() {
      const res = await fetch(`${rootURL}/work_done_in_the_pasts`);
      const data = await res.json();
      setWorkOptions(data);
    }
    getWorkDone();
  }, []);

  useEffect(function () {
    async function getAnxiousOptions() {
      const res = await fetch(`${rootURL}/most_anxious_abouts`);
      const data = await res.json();
      setAnxiousOptions(data);
    }
    getAnxiousOptions();
  }, []);

  useEffect(function () {
    async function getMedicationsOptions() {
      const res = await fetch(`${rootURL}/medications`);
      const data = await res.json();
      setMedicationsOptions(data);
    }
    getMedicationsOptions();
  }, []);

  useEffect(function () {
    async function getAllergicOptions() {
      const res = await fetch(`${rootURL}/allergens`);
      const data = await res.json();
      setAllergicOptions(data);
    }
    getAllergicOptions();
  }, []);

  if (isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="endo-app">
      <BrowserRouter>
        <Routes>
          <Route
            path="/patient_landing/:patient_token"
            element={<PatientLanding />}
          />
          <Route
            path="assistant_form/:patient_id"
            element={
              <AuthenticationGuard
                component={AssistantForm}
                toothSensitivityOptions={toothSensitivityOptions}
                workOptions={workOptions}
                anxiousOptions={anxiousOptions}
                medicationsOptions={medicationsOptions}
                allergicOptions={allergicOptions}
              />
            }
          />
          <Route
            path="admin_dashboard"
            element={<AuthenticationGuard component={AdminDashboard} />}
          />
          <Route
            path="patient/:patient_token"
            element={
              <AppLayout>
                <PatientFormContainer
                  toothSensitivityOptions={toothSensitivityOptions}
                  workOptions={workOptions}
                  anxiousOptions={anxiousOptions}
                  medicationsOptions={medicationsOptions}
                  allergicOptions={allergicOptions}
                />
              </AppLayout>
            }
          />
          <Route
            path="patient_done/:patient_token"
            element={<PatientFinished />}
          />
          <Route path="admin_login" element={<AdminLogin />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
