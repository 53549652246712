import "./style/radio_button_options.css";

export function RadioButtonOptions({
  children,
  options,
  span,
  name,
  selectedId,
  handleChange,
  error = false,
}) {
  return (
    <div className={`span-${span} radio-button-options`}>
      <div className="form-label">{children}</div>
      <RadioButtonOptionsButtons
        name={name}
        setting=""
        options={options}
        selectedId={selectedId}
        handleChange={handleChange}
        error={error}
      />
    </div>
  );
}
function RadioButtonOptionsButtons({
  options,
  name,
  selectedId,
  handleChange,
  error = false,
}) {
  return (
    <div className="radio-button-options-buttons">
      {options.map((option) => (
        <div key={option.id}>
          <label
            className={
              error ? "radio-button-label-error" : "radio-button-label"
            }
          >
            <input
              type="radio"
              name={name}
              value={option.id}
              className={
                error ? "radio-button-option-error" : "radio-button-option"
              }
              checked={option.id === selectedId}
              onChange={(e) => handleChange(option.id)}
            />
            <span className={error ? "checkmark-error" : "checkmark"}></span>
            {option.name}
          </label>
        </div>
      ))}
    </div>
  );
}
