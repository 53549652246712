import { useState } from "react";
import { SearchResultsRow } from "./SearchResultRow";
import { SearchResultsNoPatient } from "./SearchResultsNoPatient";

export function SearchResultsContent({ searchPatientRecords, searchError }) {
  return (
    <div className="search-results-content">
      {searchPatientRecords.length === 0 ? (
        <SearchResultsNoPatient searchError={searchError} />
      ) : (
        searchPatientRecords.map((el) => (
          <SearchResultsRow key={el.id} patientRecord={el} />
        ))
      )}
    </div>
  );
}
