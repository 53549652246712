import { BrowserView, MobileView } from "react-device-detect";
import QuestionaireHeader from "./QuestionaireHeader";
import TabSection from "../Shared/TabSection";
import CurrentIssue from "./CurrentIssue";
import DentalHistory from "./DentalHistory";
import MedicalHistory from "./MedicalHistory";
import { useEffect, useState } from "react";
import { dummyPatientRecord } from "../AssistantForm/DummyPatientRecord";
import { useParams } from "react-router-dom";

const rootURL = `${process.env.REACT_APP_ROOT_URL}`;

export function PatientFormContainer({
  toothSensitivityOptions,
  workOptions,
  anxiousOptions,
  medicationsOptions,
  allergicOptions,
}) {
  const [tabSelected, setTabSelected] = useState(1);
  const [patientRecord, setPatientRecord] = useState(dummyPatientRecord);

  function handleTabSectionPressed(tabNumber) {
    setTabSelected(Number(tabNumber));
  }

  function handleTabSectionPressedMobilePlus(tabNumber) {
    console.log("I AM MOBILE NEXT TAB");
    tabNumber++;
    setTabSelected(Number(tabNumber));
  }

  function handleTabSectionPressedMobileMinus(tabNumber) {
    console.log("I AM MOBILE PREVIOUS TAB");
    tabNumber--;
    setTabSelected(Number(tabNumber));
  }

  function handleFinishedForm() {}

  let params = useParams();

  useEffect(
    function () {
      async function getPatientData() {
        try {
          const res = await fetch(
            `${rootURL}/patient_record_by_token?token=${params.patient_token}`
          );
          const data = await res.json();
          setPatientRecord(data);
        } catch (e) {
          console.log(e.message);
        }
      }
      getPatientData();
    },
    [params.patient_token]
  );

  async function savePatientRecord(callback = function () {}) {
    try {
      const jsonData = {
        patient_record: patientRecord,
      };
      console.log(JSON.stringify(patientRecord));
      const res = await fetch(
        `${rootURL}/patient_records/${patientRecord.id}`,
        {
          // Enter your IP address here
          headers: {
            "Content-Type": "application/json",
          },
          method: "PUT",
          mode: "cors",
          body: JSON.stringify(patientRecord), // body data type must match "Content-Type" header
        }
      );
      const data = await res.json();
      callback();
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div className="container-questions">
      <QuestionaireHeader />
      <BrowserView>
        <TabSection
          current={tabSelected}
          filled={[]}
          handleTabPressed={handleTabSectionPressed}
        />
      </BrowserView>
      <MobileView>
        <TabSection
          current={tabSelected}
          filled={[]}
          handleTabPressed={handleTabSectionPressed}
          handleTabPressedPlus={handleTabSectionPressedMobilePlus}
          handleTabPressedMinus={handleTabSectionPressedMobileMinus}
        />
      </MobileView>
      {tabSelected === 1 ? (
        <CurrentIssue
          toothSensitivityOptions={toothSensitivityOptions}
          patientRecord={patientRecord}
          handlePatientRecordChange={setPatientRecord}
          handleActionButtonClicked={handleTabSectionPressed}
          savePatientRecord={savePatientRecord}
        />
      ) : (
        ""
      )}
      {tabSelected === 2 ? (
        <DentalHistory
          workOptions={workOptions}
          anxiousOptions={anxiousOptions}
          patientRecord={patientRecord}
          handlePatientRecordChange={setPatientRecord}
          handleActionButtonClicked={handleTabSectionPressed}
          savePatientRecord={savePatientRecord}
        />
      ) : (
        ""
      )}
      {tabSelected === 3 ? (
        <MedicalHistory
          medicationsOptions={medicationsOptions}
          allergicOptions={allergicOptions}
          patientRecord={patientRecord}
          handlePatientRecordChange={setPatientRecord}
          handleActionButtonClicked={handleFinishedForm}
          savePatientRecord={savePatientRecord}
        />
      ) : (
        ""
      )}
    </div>
  );
}
